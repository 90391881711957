import { createAction } from "redux-actions";
import constants from "./constants";
import { actionCreator } from "./ActionCreatorFunction";

/**
 * START - new type of actions
 */
export const getWebsites = actionCreator("GET_WEBSITES");
export const getMenu = actionCreator("GET_MENU");
export const setWebsitesToProcessForFirstTime = actionCreator(
  "SET_WEBSITES_TO_PROCESS_FOR_FIRST_TIME"
);
export const getTopPriceDropProducts = actionCreator(
  "GET_TOP_PRICE_DROP_PRODUCTS"
);
export const getTopPopularProducts = actionCreator("GET_TOP_POPULAR_PRODUCTS");
export const getTopRecentProducts = actionCreator("GET_TOP_RECENT_PRODUCTS");
export const getProductLatestPrice = actionCreator("GET_PRODUCT_LATEST_PRICE");

// final to use for all products pages
export const getCatalogProducts = actionCreator("GET_CATALOG_PRODUCTS");

//admin actions
export const adminGetCompleteStats = actionCreator("ADMIN_GET_COMPLETE_STATS");
export const adminGetUsersList = actionCreator("ADMIN_GET_USERS_LIST");

/**
 * END - new type of actions
 */

export const showProgressBar = createAction(constants.SHOW_PROGRESS_BAR);

export const doSearchReset = createAction(constants.DO_SEARCH_RESET);

export const productWebSearchRequest = createAction(
  constants.PRODUCT_WEB_SEARCH_REQUEST
);
export const productWebSearchSuccess = createAction(
  constants.PRODUCT_WEB_SEARCH_SUCCESS
);
export const productWebSearchError = createAction(
  constants.PRODUCT_WEB_SEARCH_ERROR
);

export const toggleToBeProcessWebsite = createAction(
  constants.TOGGLE_TO_BE_PROCESS_WEBSITE
);

export const toggleSearchCategory = createAction(
  constants.TOGGLE_SEARCH_CATEGORY
);

export const toggleShowAllSearchProducts = createAction(
  constants.TOGGLE_SHOW_ALL_SEARCH_PRODUCTS
);

export const changeSortSearchProducts = createAction(
  constants.CHANGE_SORT_SEARCH_PRODUCTS
);

export const getRecentProductsRequest = createAction(
  constants.GET_RECENT_PRODUCTS_REQUEST
);
export const getRecentProductsSuccess = createAction(
  constants.GET_RECENT_PRODUCTS_SUCCESS
);
export const getRecentProductsError = createAction(
  constants.GET_RECENT_PRODUCTS_ERROR
);

export const getPopularProductsRequest = createAction(
  constants.GET_POPULAR_PRODUCTS_REQUEST
);
export const getPopularProductsSuccess = createAction(
  constants.GET_POPULAR_PRODUCTS_SUCCESS
);
export const getPopularProductsError = createAction(
  constants.GET_POPULAR_PRODUCTS_ERROR
);

export const getPriceDropProductsRequest = createAction(
  constants.GET_PRICE_DROP_PRODUCTS_REQUEST
);
export const getPriceDropProductsSuccess = createAction(
  constants.GET_PRICE_DROP_PRODUCTS_SUCCESS
);
export const getPriceDropProductsError = createAction(
  constants.GET_PRICE_DROP_PRODUCTS_ERROR
);

// export const getMenuRequest = createAction(constants.GET_MENU_REQUEST);
// export const getMenuSuccess = createAction(constants.GET_MENU_SUCCESS);
// export const getMenuError = createAction(constants.GET_MENU_ERROR);

export const getProductRequest = createAction(constants.GET_PRODUCT_REQUEST);
export const getProductSuccess = createAction(constants.GET_PRODUCT_SUCCESS);
export const getProductError = createAction(constants.GET_PRODUCT_ERROR);

export const buyNowClickRequest = createAction(constants.BUY_NOW_CLICK_REQUEST);
export const buyNowClickSuccess = createAction(constants.BUY_NOW_CLICK_SUCCESS);
export const buyNowClickError = createAction(constants.BUY_NOW_CLICK_ERROR);

// below not using in cheapshops.in

/* get all locations */
export const getAllLocationsRequest = createAction(
  constants.GET_ALL_LOCATIONS_REQUEST
);
export const getAllLocationsSuccess = createAction(
  constants.GET_ALL_LOCATIONS_SUCCESS
);
export const getAllLocationsError = createAction(
  constants.GET_ALL_LOCATIONS_ERROR
);

/* add new parking */
export const newParkingRequest = createAction(constants.NEW_PARKING_REQUEST);
export const newParkingSuccess = createAction(constants.NEW_PARKING_SUCCESS);
export const newParkingError = createAction(constants.NEW_PARKING_ERROR);

/* get my parkings*/
export const myParkingsRequest = createAction(constants.MY_PARKINGS_REQUEST);
export const myParkingsSuccess = createAction(constants.MY_PARKINGS_SUCCESS);
export const myParkingsError = createAction(constants.MY_PARKINGS_ERROR);

/* delete parking */
export const deleteParkingRequest = createAction(
  constants.DELETE_PARKING_REQUEST
);
export const deleteParkingSuccess = createAction(
  constants.DELETE_PARKING_SUCCESS
);
export const deleteParkingError = createAction(constants.DELETE_PARKING_ERROR);

/* update parking status*/
export const updateParkingStatusRequest = createAction(
  constants.UPDATE_PARKING_STATUS_REQUEST
);
export const updateParkingStatusSuccess = createAction(
  constants.UPDATE_PARKING_STATUS_SUCCESS
);
export const updateParkingStatusError = createAction(
  constants.UPDATE_PARKING_STATUS_ERROR
);

/* update parking */
export const updateParkingRequest = createAction(
  constants.UPDATE_PARKING_REQUEST
);
export const updateParkingSuccess = createAction(
  constants.UPDATE_PARKING_SUCCESS
);
export const updateParkingError = createAction(constants.UPDATE_PARKING_ERROR);

/* get location parkings*/
export const getLocationParkingsRequest = createAction(
  constants.GET_LOCATION_PARKINGS_REQUEST
);
export const getLocationParkingsSuccess = createAction(
  constants.GET_LOCATION_PARKINGS_SUCCESS
);
export const getLocationParkingsError = createAction(
  constants.GET_LOCATION_PARKINGS_ERROR
);

/* get location stats*/
export const getLocationStatsRequest = createAction(
  constants.GET_LOCATION_STATS_REQUEST
);
export const getLocationStatsSuccess = createAction(
  constants.GET_LOCATION_STATS_SUCCESS
);
export const getLocationStatsError = createAction(
  constants.GET_LOCATION_STATS_ERROR
);

/* admin new location */
export const adminNewLocationRequest = createAction(
  constants.ADMIN_NEW_LOCATION_REQUEST
);
export const adminNewLocationSuccess = createAction(
  constants.ADMIN_NEW_LOCATION_SUCCESS
);
export const adminNewLocationError = createAction(
  constants.ADMIN_NEW_LOCATION_ERROR
);

/* get my profile */
export const getMyProfileRequest = createAction(
  constants.GET_MY_PROFILE_REQUEST
);
export const getMyProfileSuccess = createAction(
  constants.GET_MY_PROFILE_SUCCESS
);
export const getMyProfileError = createAction(constants.GET_MY_PROFILE_ERROR);

export const updateMyProfileRequest = createAction(
  constants.UPDATE_MY_PROFILE_REQUEST
);
export const updateMyProfileSuccess = createAction(
  constants.UPDATE_MY_PROFILE_SUCCESS
);
export const updateMyProfileError = createAction(
  constants.UPDATE_MY_PROFILE_ERROR
);

/* UPDATE PASSWORD */
export const updatePasswordRequest = createAction(
  constants.UPDATE_PASSWORD_REQUEST
);
export const updatePasswordSuccess = createAction(
  constants.UPDATE_PASSWORD_SUCCESS
);
export const updatePasswordError = createAction(
  constants.UPDATE_PASSWORD_ERROR
);

/* update location status*/
export const updateLocationStatusRequest = createAction(
  constants.UPDATE_LOCATION_STATUS_REQUEST
);
export const updateLocationStatusSuccess = createAction(
  constants.UPDATE_LOCATION_STATUS_SUCCESS
);
export const updateLocationStatusError = createAction(
  constants.UPDATE_LOCATION_STATUS_ERROR
);

/* update location */
export const updateLocationRequest = createAction(
  constants.UPDATE_LOCATION_REQUEST
);
export const updateLocationSuccess = createAction(
  constants.UPDATE_LOCATION_SUCCESS
);
export const updateLocationError = createAction(
  constants.UPDATE_LOCATION_ERROR
);

export const adminGetUsersRequest = createAction(
  constants.ADMIN_GET_USERS_REQUEST
);
export const adminGetUsersSuccess = createAction(
  constants.ADMIN_GET_USERS_SUCCESS
);
export const adminGetUsersError = createAction(constants.ADMIN_GET_USERS_ERROR);

export const adminGetParkingsRequest = createAction(
  constants.ADMIN_GET_PARKINGS_REQUEST
);
export const adminGetParkingsSuccess = createAction(
  constants.ADMIN_GET_PARKINGS_SUCCESS
);
export const adminGetParkingsError = createAction(
  constants.ADMIN_GET_PARKINGS_ERROR
);

export const toggleFavouriteProductRequest = createAction(
  constants.TOGGLE_FAVOURITE_PRODUCT_REQUEST
);
export const toggleFavouriteProductSuccess = createAction(
  constants.TOGGLE_FAVOURITE_PRODUCT_SUCCESS
);
export const toggleFavouriteProductError = createAction(
  constants.TOGGLE_FAVOURITE_PRODUCT_ERROR
);

export const getMyFullDataRequest = createAction(
  constants.GET_MY_FULL_DATA_REQUEST
);
export const getMyFullDataSuccess = createAction(
  constants.GET_MY_FULL_DATA_SUCCESS
);
export const getMyFullDataError = createAction(
  constants.GET_MY_FULL_DATA_ERROR
);

/* OLD */
/* OLD */
/* OLD */
/* OLD */
/* OLD */
/* OLD */

export const registerRequest = createAction(constants.REGISTER_REQUEST);
export const registerSuccess = createAction(constants.REGISTER_SUCCESS);
export const registerError = createAction(constants.REGISTER_ERROR);

export const loginRequest = createAction(constants.LOGIN_REQUEST);
export const loginSuccess = createAction(constants.LOGIN_SUCCESS);
export const loginError = createAction(constants.LOGIN_ERROR);

export const isLoggedInUser = createAction(constants.IS_LOGGEDIN_USER);

export const logoutRequest = createAction(constants.LOGOUT_REQUEST);
export const logoutRequestSuccess = createAction(
  constants.LOGOUT_REQUEST_SUCCESS
);
export const logoutRequestError = createAction(constants.LOGOUT_REQUEST_ERROR);

export const newLinkRequest = createAction(constants.NEW_LINK_REQUEST);
export const newLinkSuccess = createAction(constants.NEW_LINK_SUCCESS);
export const newLinkError = createAction(constants.NEW_LINK_ERROR);

export const updateLinkRequest = createAction(constants.UPDATE_LINK_REQUEST);
export const updateLinkSuccess = createAction(constants.UPDATE_LINK_SUCCESS);
export const updateLinkError = createAction(constants.UPDATE_LINK_ERROR);

export const myLinksRequest = createAction(constants.MY_LINKS_REQUEST);
export const myLinksSuccess = createAction(constants.MY_LINKS_SUCCESS);
export const myLinksError = createAction(constants.MY_LINKS_ERROR);

export const getPublicShortShoutRequest = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_REQUEST
);
export const getPublicShortShoutSuccess = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_SUCCESS
);
export const getPublicShortShoutError = createAction(
  constants.GET_PUBLIC_SHORT_SHOUT_ERROR
);

export const deleteLinkRequest = createAction(constants.DELETE_LINK_REQUEST);
export const deleteLinkSuccess = createAction(constants.DELETE_LINK_SUCCESS);
export const deleteLinkError = createAction(constants.DELETE_LINK_ERROR);

export const resetPasswordRequest = createAction(
  constants.RESET_PASSWORD_REQUEST
);
export const resetPasswordSuccess = createAction(
  constants.RESET_PASSWORD_SUCCESS
);
export const resetPasswordError = createAction(constants.RESET_PASSWORD_ERROR);

export const updateLinkCountRequest = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_REQUEST
);
export const updateLinkCountSuccess = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_SUCCESS
);
export const updateLinkCountError = createAction(
  constants.UPDATE_LINK_CLICK_COUNT_ERROR
);

export const updateLinkStatusRequest = createAction(
  constants.UPDATE_LINK_STATUS_REQUEST
);
export const updateLinkStatusSuccess = createAction(
  constants.UPDATE_LINK_STATUS_SUCCESS
);
export const updateLinkStatusError = createAction(
  constants.UPDATE_LINK_STATUS_ERROR
);

export const verifyEmailRequest = createAction(constants.VERIFY_EMAIL_REQUEST);
export const verifyEmailSuccess = createAction(constants.VERIFY_EMAIL_SUCCESS);
export const verifyEmailError = createAction(constants.VERIFY_EMAIL_ERROR);

export const sendEmailVerifyCodeRequest = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_REQUEST
);
export const sendEmailVerifyCodeSuccess = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_SUCCESS
);
export const sendEmailVerifyCodeError = createAction(
  constants.SEND_EMAIL_VERIFY_CODE_ERROR
);

export const getRecentlyAddedUsersRequest = createAction(
  constants.GET_RECENTLY_ADDED_USERS_REQUEST
);
export const getRecentlyAddedUsersSuccess = createAction(
  constants.GET_RECENTLY_ADDED_USERS_SUCCESS
);
export const getRecentlyAddedUsersError = createAction(
  constants.GET_RECENTLY_ADDED_USERS_ERROR
);

export const myStatsRequest = createAction(constants.MY_STATS_REQUEST);
export const myStatsSuccess = createAction(constants.MY_STATS_SUCCESS);
export const myStatsError = createAction(constants.MY_STATS_ERROR);

export const adminGetLatestUsersRequest = createAction(
  constants.ADMIN_GET_LATEST_USERS_REQUEST
);
export const adminGetLatestUsersSuccess = createAction(
  constants.ADMIN_GET_LATEST_USERS_SUCCESS
);
export const adminGetLatestUsersError = createAction(
  constants.ADMIN_GET_LATEST_USERS_ERROR
);

export const adminGetLatestLinksRequest = createAction(
  constants.ADMIN_GET_LATEST_LINKS_REQUEST
);
export const adminGetLatestLinksSuccess = createAction(
  constants.ADMIN_GET_LATEST_LINKS_SUCCESS
);
export const adminGetLatestLinksError = createAction(
  constants.ADMIN_GET_LATEST_LINKS_ERROR
);

export const adminGetCompleteStatsRequest = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_REQUEST
);
export const adminGetCompleteStatsSuccess = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_SUCCESS
);
export const adminGetCompleteStatsError = createAction(
  constants.ADMIN_GET_COMPLETE_STATS_ERROR
);

export const adminSendDoVerificationEmailRequest = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST
);
export const adminSendDoVerificationEmailSuccess = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_SUCCESS
);
export const adminSendDoVerificationEmailError = createAction(
  constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_ERROR
);

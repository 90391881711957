import React from "react";
import {
  Card,
  Avatar,
  Popconfirm,
  Switch,
  Row,
  Col,
  Statistic,
  Button,
} from "antd";
import moment from "moment";

import { LikeOutlined } from "@ant-design/icons";

import { getUserShortShoutUrl } from "../../services";

const { Meta } = Card;

class UsersList extends React.Component {
  //   _onDeleteLink = () => {
  //     this.props.doDeleteLink(this.props.data);
  //   };

  //   _onEditLink = (e) => {
  //     e.preventDefault();
  //     this.props.onEditLink(this.props.data);
  //   };

  //   _changeLinkStatus = (newStatus) => {
  //     this.props.doLinkStatusChange({
  //       newStatus: newStatus,
  //       linkData: this.props.data,
  //     });
  //   };

  render() {
    // const { data } = this.props;
    // let avtarText =
    //   (this.props.data.linkType &&
    //     this.props.data.linkType.charAt(0).toUpperCase()) ||
    //   "";
    // let clickCount = 0;
    // if (this.props.data && this.props.data.count_click) {
    //   clickCount = this.props.data.count_click;
    // }
    // let linkIsActive = false;
    // if (data.status === "active") {
    //   linkIsActive = true;
    // }

    // let url = this.props.data.url;
    // let prefix = "http://";
    // let prefix2 = "https://";
    // if (
    //   url.substr(0, prefix.length) !== prefix &&
    //   url.substr(0, prefix2.length) !== prefix2
    // ) {
    //   url = prefix + url;
    // }

    const { usersList } = this.props;

    // let avtarText = "A";
    let clickCount = 10;
    return (
      <>
        {usersList.map((user, i) => {
          let {
            _id,
            username,
            email,
            name,
            status,
            role,
            createdAt,
            isEmailVerified,
            updatedAt,
            user_links,
            user_stats,
            user_links_deleted,
            isSendDoVerificationEmailRequest,
          } = user;
          let url = getUserShortShoutUrl(user);

          let avtarText = username.charAt(0).toUpperCase();

          let joinedOn = moment(createdAt).format(
            "Do MMMM YYYY (ddd), h:mm:ss a"
          );
          let updatedOn = moment(updatedAt).format(
            "Do MMMM YYYY (ddd), h:mm:ss a"
          );

          let status_isEmailVerified = isEmailVerified ? "YES" : "NO";

          let blockBackgroundColor = "";
          if (isEmailVerified) {
            blockBackgroundColor = "#cef4ce";
          } else {
            blockBackgroundColor = "#f4ddb4";
          }
          return (
            <Card
              key={i}
              style={{
                marginBottom: "5px",
                background: blockBackgroundColor,
                border: "1px solid #52acff",
              }}
              title={
                <Row>
                  <Col xl={20} md={20} sm={24} xs={24}>
                    <Avatar
                      style={{
                        color: "white",
                        backgroundColor: "#1890FF",
                        fontWeight: "bold",
                        fontSize: "26px",
                        marginRight: "10px",
                      }}
                    >
                      {i + 1}
                    </Avatar>{" "}
                    Username: <b>{username}</b>
                  </Col>
                  <Col xl={4} md={4} sm={24} xs={24}>
                    <b>{status}</b>
                  </Col>
                </Row>
              }
              //   actions={[
              //     <div style={{ color: "black" }}>Clicks - {clickCount}</div>,
              //     <div>
              //       <Switch
              //       //   defaultChecked={linkIsActive}
              //       //   onChange={this._changeLinkStatus}
              //       />
              //     </div>,
              //     <div>
              //       <a
              //         href="#"
              //         onClick={this._onEditLink}
              //         style={{ color: "#1890ff" }}
              //       >
              //         <b>Edit</b>
              //       </a>
              //     </div>,
              //     <div>
              //       <Popconfirm
              //         title="Are you sure you want delete this link?"
              //         onConfirm={this._onDeleteLink}
              //         okText="Yes"
              //         cancelText="No"
              //       >
              //         <a href="#" style={{ color: "red" }}>
              //           <b>Delete</b>
              //         </a>
              //       </Popconfirm>
              //     </div>,
              //   ]}
            >
              <Meta
                // avatar={
                //   <Avatar
                //     style={{
                //       color: "white",
                //       backgroundColor: "#1890FF",
                //       fontWeight: "bold",
                //       fontSize: "26px",
                //     }}
                //   >
                //     {avtarText}
                //   </Avatar>
                // }
                // title={
                //   username +
                //   "   (" +
                //   status.toUpperCase() +
                //   ")" +
                //   "   (Email Verified: " +
                //   status_isEmailVerified +
                //   ")"
                // }
                description={
                  <Row>
                    <Col xl={16} md={16} sm={24} xs={24}>
                      <div style={{ color: "black" }}>
                        Role: <b>{role}</b>
                        <br />
                        Email Verified: <b>{status_isEmailVerified}</b>
                        <br />
                        Email: <b>{email}</b>
                        <br />
                        Name: <b>{name}</b>
                        <br />
                        Joined: <b>{joinedOn}</b>
                        <br />
                        Updated: <b>{updatedOn}</b>
                        <br />
                        Short Shout URL:{" "}
                        <a href={url} target="_BLANK">
                          <b>{url}</b>
                        </a>
                        <br />
                        <Row
                          gutter={16}
                          style={{
                            borderTop: "1px solid #52acff",
                            marginTop: "20px",
                          }}
                        >
                          <Col span={6}>
                            <Statistic
                              title="Page Impressions"
                              value={
                                (user_stats && user_stats.pageViewsCount) ||
                                "--"
                              }
                            />
                          </Col>
                          <Col span={6}>
                            <Statistic
                              title="Link Clicks"
                              value={
                                (user_stats && user_stats.linksClickedCount) ||
                                "--"
                              }
                            />
                          </Col>
                          <Col span={6}>
                            <Statistic
                              title="Total Links"
                              value={user_links.length || "--"}
                            />
                          </Col>
                          <Col span={6}>
                            <Statistic
                              title="Links Deleted"
                              value={user_links_deleted.length || "--"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xl={8} md={8} sm={24} xs={24}>
                      <div
                        style={{ border: "1px solid black", padding: "4px" }}
                      >
                        Send Do Email Verification block
                        <br />
                        {isSendDoVerificationEmailRequest
                          ? "Last Sent:  " +
                            moment
                              .unix(isSendDoVerificationEmailRequest)
                              .format("DD-MM-YYYY hh:mm:ss A")
                          : "Not Sent"}
                        <br />
                        {isEmailVerified ? null : (
                          <Button
                            size="small"
                            type="primary"
                            onClick={() =>
                              this.props.adminSendDoVerificationEmailRequest({
                                _id: _id,
                              })
                            }
                          >
                            Send Do Verification Email
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                }
              />
            </Card>
          );
        })}
      </>
    );
  }
}

export default UsersList;

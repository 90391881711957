import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import * as actions from "../../redux/actions";

function WebsitesAvailable(props) {
  const [showWebsitesPanel, setShowWebsitesPanel] = useState(false);
  const { websitesToProcess, menu, searchCategory } = props;
  const searchCategories = menu.searchCategories || [];

  const toggleWebsite = (wp) => {
    props.toggleToBeProcessWebsite({
      data: wp,
    });
  };

  const toggleSearchCategory = (wp) => {
    props.toggleSearchCategory({
      data: wp,
    });
  };

  const toggleWebsitesPanel = () => {
    setShowWebsitesPanel(!showWebsitesPanel);
  };

  let findDefaultSearchCategory = searchCategories.find(
    (c) => c.defaultSearchCategory
  );
  let defaultSelectedSearchCategoryId =
    (findDefaultSearchCategory && findDefaultSearchCategory?.categoryId) || "";

  let localStorageCategorySelected = localStorage.getItem("SEARCH_CATEGORY");
  if (localStorageCategorySelected) {
    let chk = JSON.parse(localStorageCategorySelected);
    if (chk && chk?.categoryId) {
      defaultSelectedSearchCategoryId = chk?.categoryId || "";
    }
  }

  return (
    <div id="landing-websites-available">
      <div
        style={{
          color: "#1f1c4f",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 11,
        }}
      >
        {/* select search category */}
        <span
          style={{
            color: "#1890ff",
            textAlign: "center",
            fontWeight: "bold",
            cursor: "pointer",
            marginLeft: 4,
            fontSize: 11,
          }}
          onClick={toggleWebsitesPanel}
        >
          OR select search websites
        </span>
      </div>
      <Row style={{ justifyContent: "center", display: "none" }}>
        {searchCategories
          // .filter((w) => !w.isDisabled)
          .map((wp, index) => {
            const { categoryId, categoryName } = wp;
            let blockClass = "website-block search-category-block";
            let isSelected = false;
            if (categoryId === defaultSelectedSearchCategoryId) {
              isSelected = true;
            }
            if (searchCategory && searchCategory.categoryId === categoryId) {
              isSelected = true;
            }
            if (!isSelected) {
              blockClass += " block-not-selected";
            } else {
              blockClass += " block-selected";
            }
            return (
              <Col
                key={index}
                className={blockClass}
                xl={2}
                md={4}
                xs={5}
                onClick={() => toggleSearchCategory(wp)}
              >
                {categoryName}
              </Col>
            );
          })}
      </Row>
      <br />

      {showWebsitesPanel ? (
        <Row style={{ height: 200, overflowY: "scroll" }}>
          {websitesToProcess
            .filter((w) => !w.isDisabled)
            .map((wp, index) => {
              const { selectedForProcess, logo, website } = wp;
              let blockClass = "website-block";
              if (!selectedForProcess) {
                blockClass += " block-not-selected-website";
              }
              return (
                <Col
                  key={index}
                  className={blockClass}
                  xl={3}
                  md={3}
                  xs={4}
                  onClick={() => toggleWebsite(wp)}
                >
                  <img src={logo} className="website-logo" alt={website} />
                  {selectedForProcess ? (
                    <CheckOutlined className="is-selected" />
                  ) : null}
                </Col>
              );
            })}
        </Row>
      ) : null}

      {/* <br />
      <Row>
        <div className="search-text-hint" style={{ margin: "0 auto" }}>
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/popular-products"
          >
            Popular
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/recent-products"
          >
            Recent
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/price-drop-products"
          >
            Price Drops
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/products"
          >
            Catalog
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/stores"
          >
            Stores
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            style={{ color: "#1f1c4f", textDecoration: "underline" }}
            to="/all-categories"
          >
            All Categories
          </Link>
        </div>
      </Row> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
  menu: state.generic.menu.data || {},
  searchCategory: state.websearch.productWebSearch.searchCategory || false,
});

const mapDispatchToProps = (dispatch) => ({
  toggleToBeProcessWebsite: (data) =>
    dispatch(actions.toggleToBeProcessWebsite(data)),
  toggleSearchCategory: (data) => dispatch(actions.toggleSearchCategory(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsitesAvailable);

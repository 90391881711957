import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Row, Col, Tabs } from "antd";
import SideDrawer from "../SideDrawer";
// import FormAddNewShortShout from "../forms/FormAddNewShortShout";
import * as actions from "../../redux/actions";
// import MyStats from "./MyStats";
import UsersList from "./UsersList";
import LinksList from "./LinksList";
import AdminStats from "./AdminStats";
// import ShortShout from "../shortshout";
// import FormEmailVerification from "../forms/FormEmailVerification";

import StatsCountryWise from "./StatsCountryWise";

import { get_tempVariableToHold_isEmailVerified } from "../../services";

const { TabPane } = Tabs;

function Admin(props) {
  useEffect(() => {
    props.adminGetCompleteStatsRequest();
    props.adminGetUsersListRequest();
  }, []);

  const { admin } = props;
  const { usersList, linksList, stats } = props;

  return (
    <>
      <AdminStats stats={admin?.stats?.data} />

      <StatsCountryWise />

      {/* <Row>
          <Col md={24} xl={24} sm={24} xs={24} style={{ padding: "10px" }}>
            <Tabs
              onChange={(tabKey) => {
                // console.log(tabKey);
              }}
              type="card"
            >
              <TabPane tab="Latest Users" key="1">
                <UsersList
                  usersList={usersList.data}
                  adminSendDoVerificationEmailRequest={
                    this.props.adminSendDoVerificationEmailRequest
                  }
                />
              </TabPane>
              <TabPane tab="Latest Links" key="2">
                <LinksList linksList={linksList.data} />
              </TabPane>
            </Tabs>
          </Col>
        </Row> */}
    </>
  );
}
// }

const mapStateToProps = (state) => ({
  // newLink: state.links.newLink || false,
  loggedUserData: state.auth.login.data || false,
  admin: state.admin,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetCompleteStatsRequest: (data) =>
    dispatch(actions.adminGetCompleteStats.request()),
  adminGetUsersListRequest: (data) =>
    dispatch(actions.adminGetUsersList.request()),
  // adminGetLatestUsersRequest: (data) =>
  //   dispatch(actions.adminGetLatestUsersRequest(data)),
  // adminGetLatestLinksRequest: (data) =>
  //   dispatch(actions.adminGetLatestLinksRequest(data)),
  // adminGetCompleteStatsRequest: (data) =>
  //   dispatch(actions.adminGetCompleteStatsRequest(data)),
  // adminGetUsersListRequest: (data) =>
  //   dispatch(actions.adminGetUsersListRequest(data)),
  // adminSendDoVerificationEmailRequest: (data) =>
  //   dispatch(actions.adminSendDoVerificationEmailRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));

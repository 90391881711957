import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Row, Col } from "antd";
import SideDrawer from "../SideDrawer";
// import FormEditParking from "../forms/FormEditParking";
import * as actions from "../../redux/actions";
import MyStats from "./MyStats";
import ManageLink from "./ManageLink";
import ManageParking from "./ManageParking";
import ShortShout from "../shortshout";

import { get_tempVariableToHold_isEmailVerified } from "../../services";

class MyParkings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditParkingForm: false,
      currentEditParkingData: false,
    };
  }

  componentDidMount() {}

  _onDeleteParking = (data) => {
    this.props.deleteParkingRequest(data);
  };

  _onEditParking = (data) => {
    this.setState({
      openEditParkingForm: true,
      currentEditParkingData: data,
    });
  };

  _doParkingStatusChange = (data) => {
    this.props.updateParkingStatusRequest({
      _id: data.parkingData._id,
      status: data.newStatus === true ? "active" : "inactive",
    });
  };

  // _doUpdateParking = (data) => {
  //   this.props.doUpdateParking({
  //     _id: data._id,
  //     values: data.values,
  //   });
  // }

  render() {
    let { myParkings } = this.props;
    return (
      <Row id="my-parkings">
        <Col xl={24} md={24} xs={24}>
          <h3>Dashboard</h3>
          {myParkings.map((parking, i) => {
            return (
              <ManageParking
                key={i}
                data={parking}
                doDeleteParking={this._onDeleteParking}
                doParkingStatusChange={this._doParkingStatusChange}
                onEditParking={this._onEditParking}
                doUpdateParking={this.props.updateParkingRequest}
              />
            );
          })}
        </Col>

        {/* {this.state.openEditParkingForm ? (
          <SideDrawer
            placement="left"
            title="Edit Parking"
            open={this.state.openEditParkingForm}
            onSideDrawerClose={() => {
              this.setState({
                openEditParkingForm: false,
              });
            }}
          >
            <FormEditParking
              currentEditParkingData={this.state.currentEditParkingData}
            />
          </SideDrawer>
        ) : null} */}
        {/* <Col xl={10} md={10} xs={24} className="dashboard-sample-view"> */}
        {/* <b>My Short Shout URL: </b>
            <a
              href={myShortShoutUrl}
              target="_BLANK"
              rel="noopener noreferrer"
              style={{
                color: "#1890FF",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              {myShortShoutUrl}
            </a>
            <br />
            <br />
            <div className="sample-short-shout-box">
              <ShortShout
                username={loggedUserData.username}
                links={linksToShow}
              />
            </div> */}
        {/* </Col> */}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  // myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  deleteParkingRequest: (data) => dispatch(actions.deleteParkingRequest(data)),
  updateParkingStatusRequest: (data) =>
    dispatch(actions.updateParkingStatusRequest(data)),
  updateParkingRequest: (data) => dispatch(actions.updateParkingRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyParkings)
);

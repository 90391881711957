import React, { useState, useEffect } from "react";

import { Line } from "@ant-design/plots";

const ProductPriceGraph = (props) => {
  const { productPrices } = props;
  let data = [];
  if (productPrices && productPrices.length > 0) {
    productPrices.forEach((p) => {
      const { price, date } = p;
      if (price && date) {
        data.push({
          date: date,
          price: price,
        });
      }
    });
  }

  const config = {
    data,
    height: 250,
    xField: "date",
    yField: "price",
    xAxis: {
      label: {
        style: {
          fill: "#1f1c4f",
        },
      },
      // grid: {
      //   line: {
      //     style: {
      //       stroke: "yellow",
      //     },
      //     opacity: 0.1,
      //   },
      // },
    },
    yAxis: {
      label: {
        style: {
          fill: "#1f1c4f",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#58af3b",
          },
          opacity: 0.1,
        },
      },
    },
    label: false,
    // label: {
    //   style: {
    //     fill: "white",
    //   },
    //   content: function content(item) {
    //     // console.log(item);
    //     //return "".concat(item.y.toFixed(2), "%");
    //     return "Rs." + item.price;
    //   },
    // },
    lineStyle: {
      lineWidth: 2,
      stroke: "#1f1c4f",
    },
    // backgroundColor: "yellow",
    color: "#1f1c4f",
    point: {
      size: 1,
      color: "#1f1c4f",
      shape: "circle",
      style: {
        fill: "#1f1c4f",
        stroke: "#1f1c4f",
        lineWidth: 2,
        color: "yellow",
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#1f1c4f",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return <Line {...config} />;
};

export default ProductPriceGraph;

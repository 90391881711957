import React from "react";
import { Link } from "react-router-dom";
import { Card, Avatar } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

class ShortShoutLink extends React.Component {
  _openLink = () => {
    this.props.onClick(this.props.link);

    let url = this.props.link.url;
    console.log(url);
    let prefix = "http://";
    let prefix2 = "https://";
    if (
      url.substr(0, prefix.length) !== prefix &&
      url.substr(0, prefix2.length) !== prefix2
    ) {
      url = prefix + url;
    }
    console.log(url);
    let win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    let newLink = this.props.link.url;
    return (
      <Card className="shortshout-link-buttton" onClick={this._openLink}>
        <Meta
          title={
            <div
              style={{
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {this.props.link.title}
            </div>
          }
        />
      </Card>
    );
  }
}

export default ShortShoutLink;

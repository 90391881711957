import React from "react";
import { Layout } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Landing from "../components/landing";

const { Content } = Layout;

class LandingPage extends React.Component {
  render() {
    return (
      <div id="disclaimer-page">
        <HeaderMain />
        <div
          className="page-content"
          style={{ color: "#1f1c4f", padding: "20px" }}
        >
          <h1>Disclaimer</h1>
          Please read the following important disclaimer before accessing
          CHEAPSHOPS.IN. CHEAPSHOPS.IN provides free information for Online
          shoppers. The products listed on our Website are not provided by
          CHEAPSHOPS.IN. Usage of this Website does not result in a binding
          agreement between CHEAPSHOPS.IN and the user in regard to the products
          offered by merchants. Prices and information on the Website are
          gathered through web programming by CHEAPSHOPS.IN, through information
          available to the public or made available to us by the merchant. Even
          though we are committed to provide accurate information, CHEAPSHOPS.IN
          does not warrant or guarantee the accuracy of prices, the description
          of the products or offer or any information published on the Website.
          CHEAPSHOPS.IN does not take responsibility for the correct functioning
          of merchant website which are shown while comparing.
        </div>
        <FooterMain />
      </div>
    );
  }
}

export default LandingPage;

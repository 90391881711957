import constants from "./constants";
import { takeLatest, all } from "redux-saga/effects";

import { getWebsites, getMenu } from "./generic/action";
import * as actions from "./actions";

import {
  getCatalogProducts,
  getTopPriceDropProducts,
  getTopPopularProducts,
  getTopRecentProducts,
} from "./products/action";

import { productWebSearchRequest } from "./websearch/action";

import {
  getProductRequest,
  toggleFavouriteProductRequest,
  buyNowClickRequest,
  /* new action with generator */
  getProductLatestPrice,
} from "./product/action";

import {
  registerRequest,
  loginRequest,
  isLoggedInUser,
  logoutRequest,
  updatePasswordRequest,
  resetPasswordRequest,
  verifyEmailRequest,
  sendEmailVerifyCodeRequest,
  getMyFullDataRequest,
  //   getMyProfileRequest,
  //   updateMyProfileRequest,
} from "./auth/action";

// import {
//   getPublicShortShoutRequest,
//   getRecentlyAddedUsersRequest,
// } from "./shortshout/action";

// import {
//   newLinkRequest,
//   myLinksRequest,
//   deleteLinkRequest,
//   updateLinkCountRequest,
//   updateLinkStatusRequest,
//   updateLinkRequest,
// } from "./links/action";

import {
  adminGetCompleteStats,
  adminGetUsersList,
  // adminGetUsersRequest,
  // adminGetLatestLinksRequest,
  // adminGetCompleteStatsRequest,
  // adminSendDoVerificationEmailRequest,
  // adminNewLocationRequest,
  // adminGetParkingsRequest,
} from "./admin/action";

// import { myStatsRequest } from "./dashboard/action";

// import { sendEmailVerifyCodeError, updateLocationError } from "./actions";

/* new */
// import {
//   getAllLocationsRequest,
//   getLocationParkingsRequest,
//   getLocationStatsRequest,
//   updateLocationStatusRequest,
//   updateLocationRequest,
// } from "./locations/action";
// import {
//   newParkingRequest,
//   myParkingsRequest,
//   deleteParkingRequest,
//   updateParkingStatusRequest,
//   updateParkingRequest,
// } from "./parkings/action";

function* watchActions() {
  /**
   * start - new actions
   */

  yield takeLatest(actions.getCatalogProducts.REQUEST, getCatalogProducts);

  yield takeLatest(actions.getWebsites.REQUEST, getWebsites);
  yield takeLatest(actions.getMenu.REQUEST, getMenu);
  yield takeLatest(
    actions.getProductLatestPrice.REQUEST,
    getProductLatestPrice
  );

  yield takeLatest(
    actions.getTopPriceDropProducts.REQUEST,
    getTopPriceDropProducts
  );
  yield takeLatest(
    actions.getTopPopularProducts.REQUEST,
    getTopPopularProducts
  );
  yield takeLatest(actions.getTopRecentProducts.REQUEST, getTopRecentProducts);

  //admin
  yield takeLatest(
    actions.adminGetCompleteStats.REQUEST,
    adminGetCompleteStats
  );
  yield takeLatest(actions.adminGetUsersList.REQUEST, adminGetUsersList);
  /**
   * end - new actions
   */

  yield takeLatest(
    constants.PRODUCT_WEB_SEARCH_REQUEST,
    productWebSearchRequest
  );

  // yield takeLatest(constants.GET_MENU_REQUEST, getMenuRequest);

  yield takeLatest(constants.BUY_NOW_CLICK_REQUEST, buyNowClickRequest);

  yield takeLatest(constants.GET_PRODUCT_REQUEST, getProductRequest);

  yield takeLatest(
    constants.TOGGLE_FAVOURITE_PRODUCT_REQUEST,
    toggleFavouriteProductRequest
  );

  /*get user full data */
  yield takeLatest(constants.GET_MY_FULL_DATA_REQUEST, getMyFullDataRequest);

  /*register*/
  yield takeLatest(constants.REGISTER_REQUEST, registerRequest);

  /*login*/
  yield takeLatest(constants.LOGIN_REQUEST, loginRequest);

  /* check if user is logged in*/
  yield takeLatest(constants.IS_LOGGEDIN_USER, isLoggedInUser);

  /*log out*/
  yield takeLatest(constants.LOGOUT_REQUEST, logoutRequest);

  /* update password */
  yield takeLatest(constants.UPDATE_PASSWORD_REQUEST, updatePasswordRequest);

  /*send email verification code*/
  yield takeLatest(
    constants.SEND_EMAIL_VERIFY_CODE_REQUEST,
    sendEmailVerifyCodeRequest
  );

  /*verify email */
  yield takeLatest(constants.VERIFY_EMAIL_REQUEST, verifyEmailRequest);

  /*reset password*/
  yield takeLatest(constants.RESET_PASSWORD_REQUEST, resetPasswordRequest);

  // /*get all locations*/
  // yield takeLatest(constants.GET_ALL_LOCATIONS_REQUEST, getAllLocationsRequest);

  // /*get location parkings*/
  // yield takeLatest(
  //   constants.GET_LOCATION_PARKINGS_REQUEST,
  //   getLocationParkingsRequest
  // );

  // /*get location stats*/
  // yield takeLatest(
  //   constants.GET_LOCATION_STATS_REQUEST,
  //   getLocationStatsRequest
  // );

  // /* parkings */
  // yield takeLatest(constants.NEW_PARKING_REQUEST, newParkingRequest);
  // yield takeLatest(constants.MY_PARKINGS_REQUEST, myParkingsRequest);
  // yield takeLatest(constants.DELETE_PARKING_REQUEST, deleteParkingRequest);
  // yield takeLatest(
  //   constants.UPDATE_PARKING_STATUS_REQUEST,
  //   updateParkingStatusRequest
  // );
  // yield takeLatest(constants.UPDATE_PARKING_REQUEST, updateParkingRequest);

  // yield takeLatest(constants.UPDATE_LOCATION_REQUEST, updateLocationRequest);
  // yield takeLatest(
  //   constants.UPDATE_LOCATION_STATUS_REQUEST,
  //   updateLocationStatusRequest
  // );

  // /* admin */
  // yield takeLatest(
  //   constants.ADMIN_NEW_LOCATION_REQUEST,
  //   adminNewLocationRequest
  // );

  // /* get my profile */
  // yield takeLatest(constants.GET_MY_PROFILE_REQUEST, getMyProfileRequest);

  // /* update my profile */
  // yield takeLatest(constants.UPDATE_MY_PROFILE_REQUEST, updateMyProfileRequest);

  // /*admin users*/
  // yield takeLatest(constants.ADMIN_GET_USERS_REQUEST, adminGetUsersRequest);

  // /*admin parkings list*/
  // yield takeLatest(
  //   constants.ADMIN_GET_PARKINGS_REQUEST,
  //   adminGetParkingsRequest
  // );
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */
  // /* OLD OLD */

  // /*new link*/
  // yield takeLatest(constants.NEW_LINK_REQUEST, newLinkRequest);

  // /*delete link*/
  // yield takeLatest(constants.DELETE_LINK_REQUEST, deleteLinkRequest);

  // /*my Links*/
  // yield takeLatest(constants.MY_LINKS_REQUEST, myLinksRequest);

  // /* get Public Short Shout */
  // yield takeLatest(
  //   constants.GET_PUBLIC_SHORT_SHOUT_REQUEST,
  //   getPublicShortShoutRequest
  // );

  // /*update link count*/
  // yield takeLatest(
  //   constants.UPDATE_LINK_CLICK_COUNT_REQUEST,
  //   updateLinkCountRequest
  // );

  // /*update link status*/
  // yield takeLatest(
  //   constants.UPDATE_LINK_STATUS_REQUEST,
  //   updateLinkStatusRequest
  // );

  // /*get recenlty added users*/
  // yield takeLatest(
  //   constants.GET_RECENTLY_ADDED_USERS_REQUEST,
  //   getRecentlyAddedUsersRequest
  // );

  // /*my stats*/
  // yield takeLatest(constants.MY_STATS_REQUEST, myStatsRequest);

  // /*update link*/
  // yield takeLatest(constants.UPDATE_LINK_REQUEST, updateLinkRequest);

  // /*admin get latest links*/
  // yield takeLatest(
  //   constants.ADMIN_GET_LATEST_LINKS_REQUEST,
  //   adminGetLatestLinksRequest
  // );

  // /*admin get complete stats*/
  // yield takeLatest(
  //   constants.ADMIN_GET_COMPLETE_STATS_REQUEST,
  //   adminGetCompleteStatsRequest
  // );

  // /*admin do send verification email*/
  // yield takeLatest(
  //   constants.ADMIN_SEND_DO_VERIFICATION_EMAIL_REQUEST,
  //   adminSendDoVerificationEmailRequest
  // );
}

export default function* rootSaga() {
  yield all([watchActions()]);
}

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Button, Layout } from "antd";

import * as actions from "../../redux/actions";

function ButtonFavouriteProduct(props) {
  let { buttonType, website, productId, favouriteProducts } = props;
  let showFavButton = false;
  let icon = <HeartOutlined style={{ fontSize: "24px !important" }} />;
  if (website && website !== "" && productId && productId !== "") {
    showFavButton = true;
  }

  let favouriteButtonText = "Add favourite";
  let btnStyle = {
    color: "#1890ff",
    fontWeight: "bold",
  };
  if (showFavButton && favouriteProducts && favouriteProducts.length > 0) {
    let isAlreadyFavourite = favouriteProducts.find(
      (p) => p.website === website && p.productId === productId
    );
    if (isAlreadyFavourite) {
      favouriteButtonText = "Remove favourite";
      btnStyle = {
        color: "#1890ff",
        fontWeight: "bold",
      };
      icon = <HeartFilled style={{ fontSize: "24px !important" }} />;
    }
  }
  let buttonTitle = favouriteButtonText;
  // favouriteButtonText = "";

  const doToggleFavourite = () => {
    // check if user is looged in if not show message to login first
    props.toggleFavouriteProductRequest({
      website: website,
      productId: productId,
    });
  };

  if (buttonType === "button") {
    // btnStyle = {
    //   // background: "black",
    //   // color: "white",
    //   fontWeight: "bold",
    //   // fontSize: 20,
    // };
  }

  return showFavButton ? (
    <div>
      <Button
        type={buttonType === "button" ? "button" : "link"}
        // type="link"
        icon={icon}
        // loading={loadings[1]}
        onClick={() => doToggleFavourite()}
        style={btnStyle}
        title={buttonTitle}
        className="addRemoveFav"
      >
        {/* {favouriteButtonText} */}
      </Button>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  favouriteProducts: state.auth.myFullData.data.favouriteProducts || [],
});

const mapDispatchToProps = (dispatch) => ({
  toggleFavouriteProductRequest: (data) =>
    dispatch(actions.toggleFavouriteProductRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonFavouriteProduct);

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Select, Button, AutoComplete, Spin } from "antd";

import * as actions from "../../redux/actions";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

class FormEditParking extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  onFinish = (values) => {
    const { currentEditParkingData } = this.props;
    this.props.updateParkingRequest({
      _id: currentEditParkingData._id,
      values: values,
    });
  };

  componentDidMount() {
    this.props.getAllLocationsRequest();
    this.setState({
      editLinkData: this.props.editLinkData,
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.editLinkData !== props.editLinkData) {
      return {
        editLinkData: props.editLinkData,
      };
    }
    return null;
  }

  render() {
    const { currentEditParkingData } = this.props;
    const { locations } = this.props;
    const { isLoading } = this.props.locations;
    let initialValues = {
      availableFor: currentEditParkingData.availableFor || "",
      parkingType: currentEditParkingData.parkingType || "",
      description: currentEditParkingData.description || "",
      comment: currentEditParkingData.comment || "",
    };

    return (
      <Form
        {...formItemLayout}
        // form={form}
        name="register"
        onFinish={this.onFinish}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          name="availableFor"
          label="Available For"
          rules={[{ required: true, message: "Select Available For" }]}
        >
          <Select placeholder="Select Available For">
            <Option value="rent">Rent</Option>
            <Option value="exchange">Exchange</Option>
            <Option value="sale">Sale</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="parkingType"
          label="Parking Type"
          rules={[{ required: true, message: "Select Parking Type" }]}
        >
          <Select placeholder="Select Parking Type">
            <Option value="open">Open Parking</Option>
            <Option value="stilt">Stilt Parking</Option>
            <Option value="basement1">Covered Basement 1</Option>
            <Option value="basement2">Covered Basement 2</Option>
            <Option value="basement3">Covered Basement 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Parking Description"
          rules={[{ max: 50 }]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item name="comment" label="Comment" rules={[{ max: 50 }]}>
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          {isLoading ? (
            <Spin />
          ) : (
            <Button type="primary" htmlType="submit">
              Update Parking
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
  locations: state.locations.locations.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  getAllLocationsRequest: (data) =>
    dispatch(actions.getAllLocationsRequest(data)),
  updateParkingRequest: (data) => dispatch(actions.updateParkingRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormEditParking)
);

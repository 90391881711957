import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* adminGetCompleteStats(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getCompleteStats`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetCompleteStats.success(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetCompleteStats.failure(e.response.data));
  }
}

export function* adminGetUsersList(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getUsersList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetUsersList.success(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetUsersList.failure(e.response.data));
  }
}

// export function* adminGetLatestLinksRequest(action) {
//   const header = {};
//   try {
//     const response = yield call(
//       fireApi,
//       "POST",
//       `/admin/getLatestLinksList`,
//       action.payload,
//       header
//     );
//     if (response) {
//       yield put(actions.adminGetLatestLinksSuccess(response.data.data));
//     }
//   } catch (e) {
//     console.log(e);
//     yield put(actions.adminGetLatestLinksError(e.response.data));
//   }
// }

// export function* adminSendDoVerificationEmailRequest(action) {
//   const header = {};
//   try {
//     const response = yield call(
//       fireApi,
//       "POST",
//       `/admin/sendDoVerificationEmailRequest`,
//       action.payload,
//       header
//     );
//     if (response) {
//       yield put(
//         actions.adminSendDoVerificationEmailSuccess(response.data.data)
//       );
//       yield put(actions.adminGetLatestUsersRequest());
//       showNotification({
//         type: "success",
//         title: (response.data && response.data && response.data.message) || "",
//       });
//     }
//   } catch (e) {
//     console.log(e);
//     yield put(actions.adminSendDoVerificationEmailError(e.response.data));
//   }
// }

// /* new */
// export function* adminNewLocationRequest(action) {
//   const header = {};
//   try {
//     const response = yield call(
//       fireApi,
//       "POST",
//       `/admin/addNewLocation`,
//       action.payload,
//       header
//     );
//     if (response) {
//       let { data } = response;
//       if (data.error) {
//         yield put(actions.adminNewLocationError(data));
//         showNotification({
//           type: "warning",
//           title: data.message,
//         });
//       } else {
//         yield put(actions.adminNewLocationSuccess(response.data));
//         showNotification({
//           type: "success",
//           title: data.message,
//         });
//         yield put(actions.getAllLocationsRequest());
//       }
//     }
//   } catch (e) {
//     yield put(actions.adminNewLocationError(e.response.data));
//     showNotification({
//       type: "error",
//       title: "Add new location Failed!!",
//       message: e.response.data.message,
//     });
//   }
// }

// export function* adminGetUsersRequest(action) {
//   const header = {};
//   try {
//     const response = yield call(
//       fireApi,
//       "POST",
//       `/admin/getUsersList`,
//       action.payload,
//       header
//     );
//     if (response) {
//       yield put(actions.adminGetUsersSuccess(response.data.data));
//     }
//   } catch (e) {
//     console.log(e);
//     yield put(actions.adminGetUsersError(e.response.data));
//   }
// }

// export function* adminGetParkingsRequest(action) {
//   console.log("----AAA");
//   console.log("----AAA");
//   console.log("----AAA");
//   const header = {};
//   try {
//     const response = yield call(
//       fireApi,
//       "POST",
//       `/admin/getParkingsList`,
//       action.payload,
//       header
//     );
//     if (response) {
//       yield put(actions.adminGetParkingsSuccess(response.data.data));
//     }
//   } catch (e) {
//     console.log(e);
//     yield put(actions.adminGetParkingsError(e.response.data));
//   }
// }

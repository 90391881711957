import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "../redux/actions";

function ProductsSort(props) {
  const { defaultSort } = props;
  let sortOptions = props?.sortOptions || [];
  return (
    <>
      {sortOptions && sortOptions.length > 0 ? (
        <select
          onChange={(e) => props.onSortChange(e.target.value)}
          style={{ fontSize: 10, height: 24, marginRight: 10 }}
        >
          {sortOptions.map((s, index) => {
            return (
              <option
                key={index}
                value={s.key}
                selected={s.key == defaultSort ? true : false}
              >
                {s.label}
              </option>
            );
          })}
        </select>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  sortOptions: state.products.products.data.sortOptions || [],
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogProductsRequest: (data) =>
    dispatch(actions.getCatalogProducts.request(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSort);

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Row, Col } from "antd";

import MyParkings from "./MyParkings";

import * as actions from "../../redux/actions";

import { get_tempVariableToHold_isEmailVerified } from "../../services";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewParkingForm: false,
    };
  }

  componentDidMount() {
    if (this.props.loggedUserData) {
      // this.props.myParkingsRequest();
    }
  }

  _openNewParkingForm = () => {
    this.setState({
      openNewParkingForm: true,
    });
  };

  render() {
    let { myParkings } = this.props;

    return <>{/* <MyParkings myParkings={myParkings} /> */}</>;
  }
}

const mapStateToProps = (state) => ({
  // myParkings: state.parkings.myParkings.data || [],
  myParkings: [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);

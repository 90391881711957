import React from "react";
// import { } from "antd";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Spin,
  Card,
  Avatar,
  Popconfirm,
  Switch,
} from "antd";

// import FormEditParking from "./FormEditParking";

const { Meta } = Card;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Option } = Select;

class ManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditParking: false,
    };
  }

  _onDeleteParking = () => {
    this.props.doDeleteParking(this.props.data);
  };

  onFinish = (values) => {
    const { data } = this.props;
    this.props.doUpdateLocation({
      _id: data._id,
      values: values,
    });
    this.setState({
      showEditParking: false,
    });
  };

  _updateParking = () => {
    const form = this.form;
    form.submit();
  };

  _onToggleEditParking = (e) => {
    e.preventDefault();
    this.setState({
      showEditParking: !this.state.showEditParking,
    });
  };

  _changeParkingStatus = (newStatus) => {
    this.props.doLocationStatusChange({
      newStatus: newStatus,
      locationData: this.props.data,
    });
  };

  _renderViewLocationActions = () => {
    const { data } = this.props;
    let location = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    return [
      <div style={{ color: "black" }}>
        {parkingIsActive ? "Active" : "Inactive"}
      </div>,
      <div>
        <Switch
          defaultChecked={parkingIsActive}
          onChange={this._changeParkingStatus}
        />
      </div>,
      <div>
        <a
          href="#"
          onClick={this._onToggleEditParking}
          style={{ color: "#1890ff" }}
        >
          <b>Edit</b>
        </a>
      </div>,
      // <div>
      //   <Popconfirm
      //     title="Are you sure you want delete this Parking?"
      //     onConfirm={this._onDeleteParking}
      //     okText="Yes"
      //     cancelText="No"
      //   >
      //     <a href="#" style={{ color: "red" }}>
      //       <b>Delete</b>
      //     </a>
      //   </Popconfirm>
      // </div>,
    ];
  };

  _renderViewLocation = () => {
    const { data } = this.props;
    let location = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }

    let url = this.props.data.url;
    let prefix = "http://";
    let prefix2 = "https://";
    return (
      <table cellPadding="5">
        <thead></thead>
        <tbody>
          {/* <tr>
                    <th colSpan="2">Parking Details</th>
                  </tr> */}
          <tr>
            <td>Location Name:</td>
            <th>{data.locationName}</th>
          </tr>
          <tr>
            <td>Address:</td>
            <th>{data.locationAddress}</th>
          </tr>
          <tr>
            <td>City:</td>
            <th>{data.locationCity}</th>
          </tr>
          <tr>
            <td>State:</td>
            <th>{data.locationState}</th>
          </tr>
          <tr>
            <td>Country:</td>
            <th>{data.locationCountry}</th>
          </tr>
        </tbody>
      </table>
    );
  };

  _renderEditLocationActions = () => {
    const { data } = this.props;
    let location = data;
    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    return [
      <div>
        <a
          href="#"
          onClick={this._updateParking}
          style={{ color: "#1890ff" }}
          form="form-edit-parking"
          key="submit"
          htmlType="submit"
        >
          <b>Update Location</b>
        </a>
      </div>,
      <div>
        <a
          href="#"
          onClick={this._onToggleEditParking}
          style={{ color: "#1890ff" }}
        >
          <b>Cancel</b>
        </a>
      </div>,
    ];
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  _renderEditLocation = () => {
    const { data } = this.props;

    const { locations } = this.props;
    // const { isLoading } = this.props.locations;
    const isLoading = false;
    let initialValues = {
      locationName: data.locationName || "",
      locationAddress: data.locationAddress || "",
      locationCity: data.locationCity || "",
      locationState: data.locationState || "",
      locationCountry: data.locationCountry || "",
    };

    return (
      <Form
        id="form-edit-parking"
        {...formItemLayout}
        ref={this.saveFormRef}
        // form={form}
        name="register"
        onFinish={this.onFinish}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item name="locationName" label="Location Name">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="locationAddress" label="Address">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="locationCity" label="City">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="locationState" label="State">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="locationCountry" label="Country">
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    );
  };

  render() {
    const { data } = this.props;
    let location = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    // if (
    //   url.substr(0, prefix.length) !== prefix &&
    //   url.substr(0, prefix2.length) !== prefix2
    // ) {
    //   url = prefix + url;
    // }
    const { showEditParking } = this.state;

    return (
      <Card
        actions={
          !showEditParking
            ? this._renderViewLocationActions()
            : this._renderEditLocationActions()
        }
      >
        <Meta
          avatar={
            <Avatar
              style={{
                color: "white",
                backgroundColor: "#1890FF",
                fontWeight: "bold",
                fontSize: "26px",
              }}
            >
              {avtarText}
            </Avatar>
          }
          title={locationFullName}
          description={
            <>
              {!showEditParking
                ? this._renderViewLocation()
                : this._renderEditLocation()}
            </>
          }
        />
      </Card>
    );
  }
}

export default ManageLocation;

import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

function HeaderDownloadAndroidApp() {
  const [showHeaderDownloadAndroidApp, setShowHeaderDownloadAndroidApp] =
    useState(true);
  const _onClick = () => {
    window.open("https://bit.ly/cheapshops-android-app");
  };
  const _onCloseClick = () => {
    sessionStorage.setItem("showHeaderDownloadAndroidApp", false);
    setShowHeaderDownloadAndroidApp(false);
  };
  let localStorageValue = sessionStorage.getItem(
    "showHeaderDownloadAndroidApp"
  );
  useEffect(() => {
    if (localStorageValue === "false") {
      setShowHeaderDownloadAndroidApp(false);
    }
  }, [localStorageValue]);
  return (
    <>
      {!showHeaderDownloadAndroidApp ? null : (
        <div
          style={{
            textAlign: "center",
            color: "black",
            borderBottom: "1px solid #1890ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <div
            style={{
              color: "black",
              marginRight: 5,
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            Download android app
          </div>
          <Button type="primary" onClick={_onClick}>
            Download
          </Button>
          <CloseOutlined
            style={{ marginLeft: 5, cursor: "pointer" }}
            onClick={_onCloseClick}
          />
        </div>
      )}
    </>
  );
}

export default HeaderDownloadAndroidApp;

import React from "react";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { Row, Col, Layout, Button, Form, Input, Spin } from "antd";

import { UserOutlined, LockOutlined } from "@ant-design/icons";

import SideDrawer from "../SideDrawer";

import * as actions from "../../redux/actions";

const { Header, Footer } = Layout;

class FormEmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerifyEmailForm: false,
    };
  }

  _toggleVerifyEmailForm = (e) => {
    e.preventDefault();
    this.setState({
      showVerifyEmailForm: !this.state.showVerifyEmailForm,
    });
  };

  _closeSideDrawer = () => {
    this.setState({
      openVerifyEmailForm: false,
    });
  };

  onFinishVerifyEmail = (values) => {
    this.props.verifyEmailRequest({
      code: values.code,
    });
  };
  render() {
    let isVerifyEmailLoading = this.props.verifyEmail.isLoading;
    let isSendEmailVerifyCodeLoading = this.props.sendEmailVerifyCode.isLoading;

    const { showVerifyEmailForm } = this.state;
    return (
      <Row id="email-verification-block">
        {!showVerifyEmailForm ? (
          <Col xl={24}>
            Please verify your Email Id{" "}
            <a href="" onClick={this._toggleVerifyEmailForm}>
              Click Here To Verify Now!!
            </a>
          </Col>
        ) : (
          <Form
            name="verifyemail"
            className="verify-form"
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={this.onFinishVerifyEmail}
          >
            <Form.Item
              name="code"
              label="Enter Verification Code"
              rules={[
                {
                  required: true,
                  message: "Please enter verification code!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {isVerifyEmailLoading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Click To Verify
              </Button>
            )}
            <Button
              type="link"
              htmlType="submit"
              className="login-form-button"
              onClick={this._toggleVerifyEmailForm}
            >
              Cancel
            </Button>

            {isSendEmailVerifyCodeLoading ? (
              <Spin />
            ) : (
              <Button
                type="link"
                htmlType="submit"
                className="login-form-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.sendEmailVerifyCodeRequest();
                }}
              >
                Resend Secret Code!!
              </Button>
            )}
          </Form>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyEmail: state.auth.verifyEmail,
  sendEmailVerifyCode: state.auth.sendEmailVerifyCode,
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmailRequest: (data) => dispatch(actions.verifyEmailRequest(data)),
  sendEmailVerifyCodeRequest: (data) =>
    dispatch(actions.sendEmailVerifyCodeRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEmailVerification);

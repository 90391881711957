import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Row, Col, Layout, Button, Menu, Dropdown } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Dashboard from "../components/dashboard";
import FormAddNewParking from "../components/dashboard/FormAddNewParking";
import FormEditProfile from "../components/dashboard/FormEditProfile";
import FormUpdatePassword from "../components/dashboard/FormUpdatePassword";

import FormEmailVerification from "../components/dashboard/FormEmailVerification";

import { get_tempVariableToHold_isEmailVerified } from "../services";

import MyFavouriteProducts from "../components/dashboard/MyFavouriteProducts";

const { Header, Content, Footer } = Layout;

class MyShortShout extends React.Component {
  render() {
    let { loggedUserData, myParkings } = this.props;

    let isEmailVerified =
      (loggedUserData && loggedUserData.isEmailVerified) || false;
    if (isEmailVerified === false) {
      isEmailVerified = get_tempVariableToHold_isEmailVerified();
    }
    return (
      <div id="mydashboard-page">
        <HeaderMain />
        <Content className="page-content">
          {isEmailVerified ? null : <FormEmailVerification />}

          {/* <div id="menu-list">
            <Link to="/mydashboard">
              <Button type="link">Dashboard</Button>
            </Link>
            <Link to="/mydashboard-addNewParking">
              <Button type="link">Add New Parking</Button>
            </Link>
            <Link to="/mydashboard-editProfile">
              <Button type="link">Edit Profile</Button>
            </Link> 
            <Link to="/mydashboard-updatePassword">
              <Button type="link">Update Password</Button>
            </Link>
          </div> */}

          <Route>
            <Route exact path="/mydashboard" component={MyFavouriteProducts} />
            {/* <Route
              path="/mydashboard-addNewParking"
              component={FormAddNewParking}
            />
            <Route
              path="/mydashboard-editProfile"
              component={FormEditProfile}
            /> */}
            <Route
              path="/mydashboard-updatePassword"
              component={FormUpdatePassword}
            />
          </Route>
        </Content>
        <FooterMain />
      </div>
    );
  }
}

export default MyShortShout;

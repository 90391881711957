import React from "react";
import { Statistic, Row, Col } from "antd";

class MyStats extends React.Component {
  render() {
    let { data } = this.props;
    let pageImpressions = "--";
    let linksClickedCount = "--";
    if (data && data.data && data.data.stats) {
      pageImpressions = data.data.stats.pageViewsCount || "--";
      linksClickedCount = data.data.stats.linksClickedCount || "--";
    }
    return (
      <Row className="my-stats">
        <Col xl={12} md={12} xs={12}>
          <Statistic title="Page Impressions" value={pageImpressions} />
        </Col>
        <Col xl={12} md={12} xs={12}>
          <Statistic title="Link Clicks" value={linksClickedCount} />
        </Col>
      </Row>
    );
  }
}

export default MyStats;

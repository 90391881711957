import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Select, Row, Col, Button, Spin } from "antd";

import * as actions from "../../redux/actions";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
    >
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
);

class FormAddNewParking extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  onFinish = (values) => {
    this.props.newParkingRequest(values);
  };

  componentDidMount() {
    this.props.getAllLocationsRequest();
    this.setState({
      editLinkData: this.props.editLinkData,
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.editLinkData !== props.editLinkData) {
      return {
        editLinkData: props.editLinkData,
      };
    }
    return null;
  }

  render() {
    const { locations, newParking } = this.props;
    const { isLoading } = this.props.locations;
    let initialValues = {};

    let addNewParkingLoading = newParking.isLoading;

    return (
      <Row id="form-add-newparking">
        <Col xl={24} md={24} xs={24}>
          <h3>Add New Parking</h3>
          <Form
            {...formItemLayout}
            // form={form}
            name="register"
            onFinish={this.onFinish}
            initialValues={initialValues}
            scrollToFirstError
          >
            <Form.Item
              name="locationId"
              label="Location"
              rules={[{ required: true, message: "Select Location" }]}
            >
              <Select
                showSearch
                placeholder="Select Location"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {locations.map((element) => {
                  let optionText =
                    element.locationName +
                    ", " +
                    element.locationAddress +
                    ", " +
                    element.locationCity +
                    ", " +
                    element.locationState;
                  return (
                    <Option key={element._id} value={element._id}>
                      {optionText}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="forVehicle"
              label="For Vehicle"
              rules={[{ required: true, message: "Select Vehicle" }]}
            >
              <Select placeholder="Select Vehicle">
                <Option value="car">Car</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="availableFor"
              label="Available For"
              rules={[{ required: true, message: "Select Available For" }]}
            >
              <Select placeholder="Select Available For">
                <Option value="rent">Rent</Option>
                <Option value="exchange">Exchange</Option>
                <Option value="sale">Sale</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="parkingType"
              label="Parking Type"
              rules={[{ required: true, message: "Select Parking Type" }]}
            >
              <Select placeholder="Select Parking Type">
                <Option value="open">Open Parking</Option>
                <Option value="stilt">Stilt Parking</Option>
                <Option value="basement1">Covered Basement 1</Option>
                <Option value="basement2">Covered Basement 2</Option>
                <Option value="basement3">Covered Basement 3</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="description"
              label="Parking Description"
              rules={[{ max: 50 }]}
            >
              <Input autoComplete="off" />
            </Form.Item>

            <Form.Item name="comment" label="Comment" rules={[{ max: 50 }]}>
              <Input autoComplete="off" />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              {addNewParkingLoading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Add Parking
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

// export default FormAddNewShortShout;

const mapStateToProps = (state) => ({
  newParking: state.parkings.newParking || false,
  loggedUserData: state.auth.login.data || false,
  locations: state.locations.locations.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  getAllLocationsRequest: (data) =>
    dispatch(actions.getAllLocationsRequest(data)),
  newParkingRequest: (data) => dispatch(actions.newParkingRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormAddNewParking)
);

import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

import { Generic } from "../../utils/index.js";

import * as actions from "../actions";

let PER_WEBSITE_RESULT = 15;

const getWebsiteToProcess = (defaultWebsitesList) => {
  let userWebsitesToProcess = Generic.getUserWebsitesToProcess();
  let ret = [];
  defaultWebsitesList.forEach((websiteObj) => {
    let selectedForProcess = false;
    if (userWebsitesToProcess && userWebsitesToProcess.length > 0) {
      let isUserSelected = userWebsitesToProcess.find(
        (w) => w.website === websiteObj.website
      );
      if (isUserSelected && isUserSelected.selectedForProcess) {
        selectedForProcess = true;
      }
    } else {
      selectedForProcess = websiteObj.isDefault || false;
    }
    let obj = {
      ...websiteObj,
      selectedForProcess: selectedForProcess,
      isProcessed: false,
    };
    ret.push(obj);
  });
  return ret;
};

// let websitesToProcess = getWebsiteToProcess();

const initialState = {
  productWebSearch: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: [],
    dataExactMatchProducts: [],
    dataAllScrapedProducts: [], // all scraped products from website
    // websitesToProcess: websitesToProcess,
    websitesToProcess: [],
    showAllSearchedProducts: false,
    sortOrder: [
      { key: "priceLowToHigh", label: "Price - Low To High" },
      { key: "priceHighToLow", label: "Price - High To Low", selected: true },
    ],
    searchCategory: false,
  },
};

// doSearchReset
const doSearchReset = (state, action) => {
  let websitesToProcess = state.productWebSearch.websitesToProcess;
  let finalWebsitesList = getWebsiteToProcess(websitesToProcess);
  return update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
      dataExactMatchProducts: { $set: [] },
      dataAllScrapedProducts: { $set: [] },
      websitesToProcess: { $set: [...finalWebsitesList] },
    },
  });
};

/* locations */
const productWebSearchRequest = (state, action) =>
  update(state, {
    productWebSearch: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const productWebSearchSuccess = (state, action) => {
  const { data } = action.payload;
  const {
    website,
    finalTextSearchProducts,
    search_products,
    productsCountToShow,
  } = data;

  let countToShow = productsCountToShow || PER_WEBSITE_RESULT;

  console.log(countToShow);

  //update processed website
  let websitesToProcess = state.productWebSearch.websitesToProcess;
  websitesToProcess.forEach((wp, index) => {
    if (wp.website === website) {
      websitesToProcess[index]["isProcessed"] = true;
    }
  });

  // exact matched prodcusts
  let dataToShow = state.productWebSearch.data || [];
  if (finalTextSearchProducts.length > 0) {
    let tmp = finalTextSearchProducts.slice(0, countToShow);
    tmp.forEach((p) => {
      if (p.name && p.name !== "") {
        dataToShow.push(p);
      }
    });
  }

  // exact match sort by price
  // if (dataToShow.length > 0) {
  //   dataToShow.sort((a, b) => a.price - b.price);
  // }

  // all scraped products
  let dataAllScrapedProducts =
    state.productWebSearch.dataAllScrapedProducts || [];
  if (search_products.length > 0) {
    let tmp = search_products.slice(0, countToShow);
    tmp.forEach((p) => {
      if (p.name && p.name !== "") {
        dataAllScrapedProducts.push(p);
      }
    });
  }
  // exact match sort by price
  if (dataAllScrapedProducts.length > 0) {
    dataAllScrapedProducts.sort((a, b) => a.price - b.price);
  }

  // do sorting
  let sortOrder = state.productWebSearch.sortOrder;
  dataToShow = Generic.sortProductsList(dataToShow, sortOrder);
  dataAllScrapedProducts = Generic.sortProductsList(
    dataAllScrapedProducts,
    sortOrder
  );

  return update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: dataToShow },
      dataExactMatchProducts: { $set: dataToShow },
      dataAllScrapedProducts: { $set: dataAllScrapedProducts },
      websitesToProcess: { $set: [...websitesToProcess] },
    },
  });
};

const productWebSearchError = (state, action) =>
  update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });

// toggleToBeProcessWebsite
const toggleToBeProcessWebsite = (state, action) => {
  const { payload } = action;
  const { data } = payload;
  const { website } = data;
  let websitesToProcess = state.productWebSearch.websitesToProcess;
  websitesToProcess.forEach((wp, index) => {
    if (wp.website === website) {
      websitesToProcess[index]["selectedForProcess"] = !wp.selectedForProcess;
    }
  });
  // start - save selected website in session storage
  let tmpArr = [];
  websitesToProcess.forEach((w) => {
    tmpArr.push({
      website: w.website,
      selectedForProcess: w.selectedForProcess,
    });
  });
  Generic.saveUserWebsitesToProcess(tmpArr);
  // end - save selected website in session storage

  return update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      websitesToProcess: { $set: [...websitesToProcess] },
    },
  });
};

// toggleSearchCategory
const toggleSearchCategory = (state, action) => {
  const { payload } = action;
  const { data } = payload;
  const { categoryWebsites } = data;
  let websitesToProcess = state.productWebSearch.websitesToProcess;
  websitesToProcess.forEach((wp, index) => {
    let webFound = categoryWebsites.find((w) => w.website === wp.website);
    if (webFound) {
      websitesToProcess[index]["selectedForProcess"] = true;
    } else {
      websitesToProcess[index]["selectedForProcess"] = false;
    }
  });
  // start - save selected website in session storage
  let tmpArr = [];
  websitesToProcess.forEach((w) => {
    tmpArr.push({
      website: w.website,
      selectedForProcess: w.selectedForProcess,
    });
  });
  // save selected search category to session storage
  Generic.saveUserSearchCategoty(data);

  Generic.saveUserWebsitesToProcess(tmpArr);
  // end - save selected website in session storage
  return update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      websitesToProcess: { $set: [...websitesToProcess] },
      searchCategory: { $set: data },
    },
  });
};

const toggleShowAllSearchProducts = (state, action) => {
  let showAllSearchedProducts = action.payload;

  let dataExactMatchProducts =
    state.productWebSearch.dataExactMatchProducts || []; // exact matched products
  let dataAllScrapedProducts =
    state.productWebSearch.dataAllScrapedProducts || [];
  // let showAllSearchedProducts = !state.productWebSearch.showAllSearchedProducts;
  let dataToShow = [];
  if (showAllSearchedProducts) {
    dataToShow = dataAllScrapedProducts;
  } else {
    dataToShow = dataExactMatchProducts;
  }

  // do sorting
  let sortOrder = state.productWebSearch.sortOrder;
  dataToShow = Generic.sortProductsList(dataToShow, sortOrder);

  return update(state, {
    productWebSearch: {
      showAllSearchedProducts: { $set: showAllSearchedProducts },
      data: { $set: dataToShow },
    },
  });
};

// change sorting
const changeSortSearchProducts = (state, action) => {
  let existingSortOrder = state.productWebSearch.sortOrder;
  let newSort = action.payload;
  existingSortOrder.forEach((s, index) => {
    if (s.key === newSort) {
      existingSortOrder[index]["selected"] = true;
    } else {
      existingSortOrder[index]["selected"] = false;
    }
  });

  let dataToShow = state.productWebSearch.data;

  // do sorting
  dataToShow = Generic.sortProductsList(dataToShow, existingSortOrder);

  // do sorting to all scrap products too since we are showing all on UI
  let dataAllScrapedProducts = state.productWebSearch.dataAllScrapedProducts;
  let tmp_dataAllScrapedProducts = Generic.sortProductsList(
    dataAllScrapedProducts,
    existingSortOrder
  );

  return update(state, {
    productWebSearch: {
      sortOrder: { $set: [...existingSortOrder] },
      data: { $set: [...dataToShow] },
      dataAllScrapedProducts: { $set: [...tmp_dataAllScrapedProducts] },
    },
  });
};

// setWebsitesToProcessForFirstTime // added on 11 feb 2023
const setWebsitesToProcessForFirstTime = (state, action) => {
  const defaultWebsitesList = action?.data?.websites || [];
  let finalWebsitesList = getWebsiteToProcess(defaultWebsitesList);
  return update(state, {
    productWebSearch: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: [] },
      dataExactMatchProducts: { $set: [] },
      dataAllScrapedProducts: { $set: [] },
      websitesToProcess: { $set: [...finalWebsitesList] },
    },
  });
};

export default handleActions(
  {
    [constants.DO_SEARCH_RESET]: doSearchReset,

    [constants.PRODUCT_WEB_SEARCH_REQUEST]: productWebSearchRequest,
    [constants.PRODUCT_WEB_SEARCH_SUCCESS]: productWebSearchSuccess,
    [constants.PRODUCT_WEB_SEARCH_ERROR]: productWebSearchError,

    [constants.TOGGLE_TO_BE_PROCESS_WEBSITE]: toggleToBeProcessWebsite,

    [constants.TOGGLE_SEARCH_CATEGORY]: toggleSearchCategory,

    [constants.TOGGLE_SHOW_ALL_SEARCH_PRODUCTS]: toggleShowAllSearchProducts,

    [constants.CHANGE_SORT_SEARCH_PRODUCTS]: changeSortSearchProducts,

    [actions.setWebsitesToProcessForFirstTime.SUCCESS]:
      setWebsitesToProcessForFirstTime,
  },
  initialState
);

import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* getProductRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/products/getProductInfo`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.getProductSuccess(response.data.data));
      // is success call user data which will fetch product information and will be used to add/remove fav button
      yield put(actions.getMyFullDataRequest());
    }
  } catch (e) {
    yield put(actions.getProductError(e.response.data));
  }
  yield put(actions.showProgressBar(false));
}

export function* toggleFavouriteProductRequest(action) {
  yield put(actions.showProgressBar(true));
  const header = {};

  const response = yield call(
    fireApi,
    "POST",
    `/products/toggleFavouriteProduct`,
    action.payload,
    header
  );
  const { data } = response;
  if (data.error) {
    yield put(actions.toggleFavouriteProductError(data));
    showNotification({
      type: "error",
      title: "Failed!!",
      message: data.message,
    });
  } else {
    yield put(actions.toggleFavouriteProductSuccess(data));
    showNotification({
      type: "success",
      title: "Success!!",
      message: data.message,
    });
    yield put(actions.getMyFullDataRequest());
  }
  yield put(actions.showProgressBar(false));
}

export function* buyNowClickRequest(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/products/buyNowClick`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.buyNowClickSuccess(response.data.data));
    }
  } catch (e) {
    yield put(actions.buyNowClickError(e.response.data));
  }
}

/**
 *
 * Request to get latest price from website
 */
export function* getProductLatestPrice(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  let apiPayload = action.data || {};
  apiPayload.isForceScrap = true;
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/products/getProductInfo`,
      action.data,
      header
    );
    if (response) {
      // getProductSuccess bcoz it will update same product redux and product page will update with latest price
      yield put(actions.getProductSuccess(response.data.data));
      // is success call user data which will fetch product information and will be used to add/remove fav button
      // yield put(actions.getMyFullDataRequest());
      yield put(actions.getProductLatestPrice.success(response.data.data));
    }
  } catch (e) {
    yield put(actions.getProductLatestPrice.failure(e.response.data));
    // yield put(actions.getProductLatestPrice.failure(e.response.data));
  }
  // yield put(actions.showProgressBar(false));
}

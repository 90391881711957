import React, { useState, useEffect } from "react";

import { Pagination } from "antd";

function PaginationPanel(props) {
  const { totalPages, currentPage } = props;
  const _onChange = (newPage) => {
    if (props.onPageChange) {
      props.onPageChange(newPage);
    }
  };
  return (
    <Pagination
      style={{ marginTop: 20 }}
      size="small"
      current={currentPage * 1}
      defaultCurrent={currentPage * 1}
      total={totalPages}
      onChange={_onChange}
      showSizeChanger={false}
      defaultPageSize={1}
    />
  );
}
export default PaginationPanel;

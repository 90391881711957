import React from "react";
import { Card, Avatar, Popconfirm, Switch, Row, Col, Statistic } from "antd";
import moment from "moment";

import { getUserShortShoutUrl } from "../../services";

const { Meta } = Card;

class LinksList extends React.Component {
  //   _onDeleteLink = () => {
  //     this.props.doDeleteLink(this.props.data);
  //   };

  //   _onEditLink = (e) => {
  //     e.preventDefault();
  //     this.props.onEditLink(this.props.data);
  //   };

  //   _changeLinkStatus = (newStatus) => {
  //     this.props.doLinkStatusChange({
  //       newStatus: newStatus,
  //       linkData: this.props.data,
  //     });
  //   };

  render() {
    // const { data } = this.props;
    // let avtarText =
    //   (this.props.data.linkType &&
    //     this.props.data.linkType.charAt(0).toUpperCase()) ||
    //   "";
    // let clickCount = 0;
    // if (this.props.data && this.props.data.count_click) {
    //   clickCount = this.props.data.count_click;
    // }
    // let linkIsActive = false;
    // if (data.status === "active") {
    //   linkIsActive = true;
    // }

    // let url = this.props.data.url;
    // let prefix = "http://";
    // let prefix2 = "https://";
    // if (
    //   url.substr(0, prefix.length) !== prefix &&
    //   url.substr(0, prefix2.length) !== prefix2
    // ) {
    //   url = prefix + url;
    // }

    const { linksList } = this.props;

    // let avtarText = "A";
    let clickCount = 10;
    return (
      <>
        {linksList.map((link, i) => {
          // console.log(link);
          let {
            linkType,
            title,
            url,
            status,
            createdAt,
            updatedAt,
            count_click,
            user_details,
          } = link;
          let avtarText = linkType.charAt(0).toUpperCase();
          let addedOn = moment(createdAt).format(
            "Do MMMM YYYY (ddd), h:mm:ss a"
          );
          let updatedOn = moment(updatedAt).format(
            "Do MMMM YYYY (ddd), h:mm:ss a"
          );
          let clickCount = 0;
          if (count_click) {
            clickCount = count_click;
          }

          let blockBackgroundColor = "";
          if (status === "active") {
            blockBackgroundColor = "#cef4ce";
          } else {
            blockBackgroundColor = "#f4ddb4";
          }

          let userShortShoutUrl = getUserShortShoutUrl(user_details);

          return (
            <Card
              key={i}
              style={{
                marginBottom: "10px",
                background: blockBackgroundColor,
                border: "1px solid #52acff",
              }}
              title={
                <Row>
                  <Col xl={20}>
                    <Avatar
                      style={{
                        color: "white",
                        backgroundColor: "#1890FF",
                        fontWeight: "bold",
                        fontSize: "26px",
                        marginRight: "10px",
                      }}
                    >
                      {avtarText}
                    </Avatar>
                    {linkType.toUpperCase() + " - " + title}
                  </Col>
                  <Col xl={4}>
                    <b>{status}</b>
                  </Col>
                </Row>
              }
              bordered={false}
              actions={[
                <div style={{ color: "black" }}>Clicks - {clickCount}</div>,
                //     <div>
                //       <Switch
                //       //   defaultChecked={linkIsActive}
                //       //   onChange={this._changeLinkStatus}
                //       />
                //     </div>,
                //     <div>
                //       <a
                //         href="#"
                //         onClick={this._onEditLink}
                //         style={{ color: "#1890ff" }}
                //       >
                //         <b>Edit</b>
                //       </a>
                //     </div>,
                //     <div>
                //       <Popconfirm
                //         title="Are you sure you want delete this link?"
                //         onConfirm={this._onDeleteLink}
                //         okText="Yes"
                //         cancelText="No"
                //       >
                //         <a href="#" style={{ color: "red" }}>
                //           <b>Delete</b>
                //         </a>
                //       </Popconfirm>
                //     </div>,
              ]}
            >
              <Meta
                // avatar={
                //   <Avatar
                //     style={{
                //       color: "white",
                //       backgroundColor: "#1890FF",
                //       fontWeight: "bold",
                //       fontSize: "26px",
                //     }}
                //   >
                //     {avtarText}
                //   </Avatar>
                // }
                // title={
                //   linkType.toUpperCase() +
                //   " - " +
                //   title +
                //   "   (" +
                //   status.toUpperCase() +
                //   ")"
                // }
                description={
                  <div style={{ color: "black" }}>
                    {/* Email: <b>{username}</b>
                    <br />
                    Name: <b>{name}</b>
                    <br /> */}
                    Added: <b>{addedOn}</b>
                    <br />
                    Updated: <b>{updatedOn}</b>
                    <br />
                    URL:{" "}
                    <a href={url} target="_BLANK">
                      <b>{url}</b>
                    </a>
                    <br />
                    <hr />
                    Username: <b>{user_details.username || "--"}</b>
                    <br />
                    Email: <b>{user_details.email || "--"}</b>
                    <br />
                    Is Email Verified:{" "}
                    <b>{user_details.isEmailVerified ? "Yes" : "No"}</b>
                    <br />
                    Status: <b>{user_details.status || "--"}</b>
                    <br />
                    User Short Shout URL:{" "}
                    <a href={userShortShoutUrl} target="_BLANK">
                      <b>{userShortShoutUrl}</b>
                    </a>
                  </div>
                }
              />
            </Card>
          );
        })}
      </>
    );
  }
}

export default LinksList;

import React, { useState, useEffect } from "react";
import { Timeline, Radio } from "antd";
import moment from "moment";

const ProductPriceHistory = (props) => {
  const { productPrices } = props;
  let data = [];
  if (productPrices && productPrices.length > 0) {
    productPrices.forEach((p) => {
      const { price, date, currency } = p;
      if (price && date) {
        data.push({
          date: date,
          price: price,
          currency: currency,
        });
      }
    });
  }
  data.reverse();

  return (
    <div style={{ maxHeight: 250, overflowY: "auto", overflowX: "hidden" }}>
      <Timeline mode="left">
        {data.map((p, index) => {
          let priceDate = moment(p.date, "DD-MM-YYYY").format("DD-MMM-YYYY");
          return (
            <Timeline.Item
              key={index}
              label={priceDate}
              style={{ color: "black", fontSize: 16 }}
            >
              <b>
                {p?.currency}
                {p?.price}
              </b>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default ProductPriceHistory;

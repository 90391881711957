import React from "react";
import { connect } from "react-redux";
import { Col } from "antd";
import { MagnifyingGlass } from "react-loader-spinner";
import * as actions from "../redux/actions";

function WebsitesLoader(props) {
  const { websitesToProcess } = props;
  let websitesToList = websitesToProcess.filter(
    (w) => w.selectedForProcess === true
  );
  return (
    <div
      style={{
        display: "flex",
        overflow: "hidden",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      {websitesToList.map((website, index) => {
        const { logo, isProcessed } = website;
        return (
          <React.Fragment key={index}>
            {isProcessed ? null : (
              <Col
                xl={2}
                md={2}
                xs={4}
                style={{
                  border: "2px solid #e0e6f8",
                  background: "#eff8fb",
                  padding: 2,
                  marginRight: 2,
                  textAlign: "center",
                }}
              >
                {/* <Spin style={{ color: "green" }} /> */}
                <MagnifyingGlass
                  visible={true}
                  height="30"
                  width="30"
                  ariaLabel="magnifying-glass-loading"
                  wrapperStyle={{}}
                  wrapperClass="magnifying-glass-wrapper"
                  glassColor="#c0efff"
                  color="#e15b64"
                />
                <img
                  src={logo}
                  style={{ width: "100%" }}
                  alt={website.website}
                />{" "}
              </Col>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({
  productWebSearchRequest: (data) =>
    dispatch(actions.productWebSearchRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsitesLoader);

import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Input, Row, Col, Button, Spin } from "antd";

import * as actions from "../../redux/actions";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class FormUpdatePassword extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  submitForm = (values) => {
    let finalValues = values;
    delete finalValues.name;
    delete finalValues.email;
    this.props.updatePasswordRequest(finalValues);
  };

  render() {
    const { updatePassword, loggedUserData } = this.props;
    let initialValues = {
      name: loggedUserData.name || "",
      email: loggedUserData.email || "",
    };

    return (
      <Row id="form-add-newparking">
        <Col xl={24} md={24} xs={24}>
          <h3>Update Password</h3>
          <Form
            {...formItemLayout}
            name="editProfile"
            onFinish={this.submitForm}
            initialValues={initialValues}
            scrollToFirstError
          >
            <Form.Item name="name" label="Name">
              <Input disabled />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="oldPassword"
              label="Old Password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
                {
                  min: 4,
                  message: "Password cannot be less than 4 characters",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              {updatePassword && updatePassword.isLoading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Update Password
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  updatePassword: state.auth.updatePassword || false,
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  updatePasswordRequest: (data) =>
    dispatch(actions.updatePasswordRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormUpdatePassword)
);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// import { withRouter } from "react-router-dom";
import { Layout, Switch, Row, Col } from "antd";
// import HeaderMain from "../components/HeaderMain";
// import FooterMain from "../components/FooterMain";
// import Search from "../components/search";

import ProductWebSearch from "../../components/ProductWebSearch";
import TemplateProductSmallView from "../product/TemplateProductSmallView";
// import WebsitesLoader from "components/WebsitesLoader";

import * as actions from "../../redux/actions";

const { Content } = Layout;

function MyFavouriteProducts(props) {
  const { favouriteProducts } = props;

  useEffect(() => {
    props.getMyFullDataRequest();
  }, []);

  // console.log(recentProducts);

  return (
    <div id="search-page">
      <h1 style={{ color: "#1f1c4f" }}>My Favourites</h1>
      {/* <HeaderMain /> */}
      {/* <Content className="page-content"><Search /></Content> */}
      {/* <FooterMain /> */}
      {/* <div className="page-content">
        <Row>
          <Col md={24} xl={24} sm={24} xs={24}>
            <h2 style={{ marginLeft: 10, fontSize: 14, color: "white" }}>
              Recent Products
            </h2>
          </Col>
          {/* <Col
            md={12}
            xl={12}
            sm={12}
            xs={24}
            style={{ textAlign: "right", paddingRight: "10px", color: "white" }}
          >
            <Switch onChange={props.toggleShowAllSearchProducts} /> Show all
          </Col> 
        </Row>

        {/* <WebsitesLoader websitesList={websites} /> */}
      {/* {favouriteProducts.map((product, index) => {
        return <ProductWebSearch data={product.productInfo} key={index} />;
      })} */}
      <Row gutter={16}>
        {favouriteProducts.map((product, index) => {
          return (
            <TemplateProductSmallView data={product.productInfo} key={index} />
          );
        })}
      </Row>
      {/* </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  favouriteProducts: state.auth.myFullData.data.favouriteProducts || [],
});

const mapDispatchToProps = (dispatch) => ({
  getMyFullDataRequest: (data) => dispatch(actions.getMyFullDataRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyFavouriteProducts);

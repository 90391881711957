import React from "react";
// import { } from "antd";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Spin,
  Card,
  Avatar,
  Popconfirm,
  Switch,
} from "antd";

import FormEditParking from "./FormEditParking";

const { Meta } = Card;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Option } = Select;

class ManageParking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditParking: false,
    };
  }

  _onDeleteParking = () => {
    this.props.doDeleteParking(this.props.data);
  };

  onFinish = (values) => {
    const { data } = this.props;
    this.props.doUpdateParking({
      _id: data._id,
      values: values,
    });
    this.setState({
      showEditParking: false,
    });
  };

  _updateParking = () => {
    const form = this.form;
    form.submit();
  };

  _onToggleEditParking = (e) => {
    e.preventDefault();
    this.setState({
      showEditParking: !this.state.showEditParking,
    });
  };

  _changeParkingStatus = (newStatus) => {
    this.props.doParkingStatusChange({
      newStatus: newStatus,
      parkingData: this.props.data,
    });
  };

  _renderViewParkingActions = () => {
    const { data } = this.props;
    const { location } = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    return [
      <div style={{ color: "black" }}>
        {parkingIsActive ? "Active" : "Inactive"}
      </div>,
      <div>
        <Switch
          defaultChecked={parkingIsActive}
          onChange={this._changeParkingStatus}
        />
      </div>,
      <div>
        <a
          href="#"
          onClick={this._onToggleEditParking}
          style={{ color: "#1890ff" }}
        >
          <b>Edit</b>
        </a>
      </div>,
      <div>
        <Popconfirm
          title="Are you sure you want delete this Parking?"
          onConfirm={this._onDeleteParking}
          okText="Yes"
          cancelText="No"
        >
          <a href="#" style={{ color: "red" }}>
            <b>Delete</b>
          </a>
        </Popconfirm>
      </div>,
    ];
  };

  _renderViewParking = () => {
    const { data } = this.props;
    const { location } = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }

    let url = this.props.data.url;
    let prefix = "http://";
    let prefix2 = "https://";
    return (
      <table cellPadding="5">
        <thead></thead>
        <tbody>
          {/* <tr>
                    <th colSpan="2">Parking Details</th>
                  </tr> */}
          <tr>
            <td>For Vehicle:</td>
            <th>{data.forVehicle}</th>
          </tr>
          <tr>
            <td>Available For:</td>
            <th>{data.availableFor}</th>
          </tr>
          <tr>
            <td>Parking Type:</td>
            <th>{data.parkingType}</th>
          </tr>
          <tr>
            <td>Parking Description:</td>
            <th>{data.description}</th>
          </tr>
          <tr>
            <td>Comment:</td>
            <th>{data.comment}</th>
          </tr>
        </tbody>
      </table>
    );
  };

  _renderEditParkingActions = () => {
    const { data } = this.props;
    const { location } = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    return [
      <div>
        <a
          href="#"
          onClick={this._updateParking}
          style={{ color: "#1890ff" }}
          form="form-edit-parking"
          key="submit"
          // htmlType="submit"
        >
          <b>Update Parking</b>
        </a>
      </div>,
      <div>
        <a
          href="#"
          onClick={this._onToggleEditParking}
          style={{ color: "#1890ff" }}
        >
          <b>Cancel</b>
        </a>
      </div>,
    ];
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  _renderEditParking = () => {
    const { data } = this.props;
    let currentEditParkingData = data;

    const { locations } = this.props;
    // const { isLoading } = this.props.locations;
    const isLoading = false;
    let initialValues = {
      availableFor: currentEditParkingData.availableFor || "",
      parkingType: currentEditParkingData.parkingType || "",
      description: currentEditParkingData.description || "",
      comment: currentEditParkingData.comment || "",
    };

    return (
      <Form
        id="form-edit-parking"
        {...formItemLayout}
        ref={this.saveFormRef}
        // form={form}
        name="register"
        onFinish={this.onFinish}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item
          name="availableFor"
          label="Available For"
          rules={[{ required: true, message: "Select Available For" }]}
        >
          <Select placeholder="Select Available For">
            <Option value="rent">Rent</Option>
            <Option value="exchange">Exchange</Option>
            <Option value="sale">Sale</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="parkingType"
          label="Parking Type"
          rules={[{ required: true, message: "Select Parking Type" }]}
        >
          <Select placeholder="Select Parking Type">
            <Option value="open">Open Parking</Option>
            <Option value="stilt">Stilt Parking</Option>
            <Option value="basement1">Covered Basement 1</Option>
            <Option value="basement2">Covered Basement 2</Option>
            <Option value="basement3">Covered Basement 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Parking Description"
          rules={[{ max: 50 }]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item name="comment" label="Comment" rules={[{ max: 50 }]}>
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    );
  };

  render() {
    const { data } = this.props;
    const { location } = data;

    let locationFullName =
      location.locationName +
      ", " +
      location.locationAddress +
      ", " +
      location.locationCity +
      ", " +
      location.locationState;

    let avtarText =
      (location &&
        location.locationName &&
        location.locationName.charAt(0).toUpperCase()) ||
      "";

    let title =
      location &&
      location.locationName + " " + location &&
      location.locationCity + " " + location &&
      location.locationState;

    let clickCount = 0;
    if (this.props.data && this.props.data.count_click) {
      clickCount = this.props.data.count_click;
    }
    let parkingIsActive = false;
    if (data.status === "active") {
      parkingIsActive = true;
    }
    // if (
    //   url.substr(0, prefix.length) !== prefix &&
    //   url.substr(0, prefix2.length) !== prefix2
    // ) {
    //   url = prefix + url;
    // }
    const { showEditParking } = this.state;

    return (
      <Card
        className="my-added-parking"
        actions={
          !showEditParking
            ? this._renderViewParkingActions()
            : this._renderEditParkingActions()
        }
      >
        <Meta
          avatar={
            <Avatar
              style={{
                color: "white",
                backgroundColor: "#1890FF",
                fontWeight: "bold",
                fontSize: "26px",
              }}
            >
              {avtarText}
            </Avatar>
          }
          title={locationFullName}
          description={
            <>
              {!showEditParking
                ? this._renderViewParking()
                : this._renderEditParking()}
            </>
          }
        />
      </Card>
    );
  }
}

export default ManageParking;

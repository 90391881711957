import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

import { Button, Row, Col, Table, Tag, Space } from "antd";
// import SideDrawer from "../SideDrawer";
// import FormEditParking from "../forms/FormEditParking";
import * as actions from "../../../redux/actions";
// import MyStats from "./MyStats";
// import ManageLink from "./ManageLink";
// import ManageLocation from "./ManageLocation";
// import ShortShout from "../shortshout";
import { get_tempVariableToHold_isEmailVerified } from "../../../services";

class Parkings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditParkingForm: false,
      currentEditParkingData: false,
    };
  }

  componentDidMount() {
    this.props.adminGetParkingsRequest();
  }

  _onDeleteParking = (data) => {
    this.props.deleteParkingRequest(data);
  };

  _onEditParking = (data) => {
    this.setState({
      openEditParkingForm: true,
      currentEditParkingData: data,
    });
  };

  _doLocationStatusChange = (data) => {
    this.props.updateLocationStatusRequest({
      _id: data.locationData._id,
      status: data.newStatus === true ? "active" : "inactive",
    });
  };

  // _doUpdateParking = (data) => {
  //   this.props.doUpdateParking({
  //     _id: data._id,
  //     values: data.values,
  //   });
  // }

  _getTableColumns = () => {
    const columns = [
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // render: (status) => (status ? "Y" : "N"),
      },
      {
        title: "Created Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (data) => moment(data).format("DD-MMM-YYYY, h:mm:ss a"),
      },
      {
        title: "Vehicle",
        dataIndex: "forVehicle",
        key: "forVehicle",
        // render: (status) => (status ? "Y" : "N"),
      },
      {
        title: "For",
        dataIndex: "availableFor",
        key: "availableFor",
        // render: (status) => (status ? "Y" : "N"),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        // render: (status) => (status ? "Y" : "N"),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        // render: (status) => (status ? "Y" : "N"),
      },
      {
        title: "Location Info",
        dataIndex: "locationDetails",
        key: "locationDetails",
        render: (data) => {
          let locationInfo = "--";
          if (data && data.locationName) {
            locationInfo =
              data.locationName +
              "\n" +
              data.locationAddress +
              ", " +
              data.locationCity +
              "\n" +
              data.locationState;
          }
          return <>{locationInfo}</>;
        },
      },
      {
        title: "User Info",
        dataIndex: "userDetails",
        key: "userDetails",
        render: (data) => {
          let userInfo = "--";
          if (data && data.name) {
            userInfo = data.name + ", " + data.email;
          }
          return <>{userInfo}</>;
        },
      },
      // {
      //   title: "Mobile Verified",
      //   dataIndex: "isMobileVerified",
      //   key: "isMobileVerified",
      //   render: (status) => (status ? "Y" : "N"),
      // },

      // {
      //   title: "Tags",
      //   key: "tags",
      //   dataIndex: "tags",
      //   render: (tags) => (
      //     <>
      //       {tags.map((tag) => {
      //         let color = tag.length > 5 ? "geekblue" : "green";
      //         if (tag === "loser") {
      //           color = "volcano";
      //         }
      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </>
      //   ),
      // },
      // {
      //   title: "Action",
      //   key: "action",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <a>Invite {record.name}</a>
      //       <a>Delete</a>
      //     </Space>
      //   ),
      // },
    ];
    return columns;
  };

  _getTableData = () => {
    let { usersList } = this.props;
    //let data =
    // const data = [
    //   {
    //     key: "1",
    //     name: "John Brown",
    //     age: 32,
    //     address: "New York No. 1 Lake Park",
    //     tags: ["nice", "developer"],
    //   },
    //   {
    //     key: "2",
    //     name: "Jim Green",
    //     age: 42,
    //     address: "London No. 1 Lake Park",
    //     tags: ["loser"],
    //   },
    //   {
    //     key: "3",
    //     name: "Joe Black",
    //     age: 32,
    //     address: "Sidney No. 1 Lake Park",
    //     tags: ["cool", "teacher"],
    //   },
    // ];
    return usersList;
  };

  render() {
    let { usersList } = this.props;
    return (
      <Row id="my-parkings">
        <Col xl={24} md={24} xs={24}>
          <h3>Parkings</h3>
          <Table
            columns={this._getTableColumns()}
            dataSource={this._getTableData()}
          />
          {/* {usersList.map((location, i) => {
            return (
              //   <ManageLocation
              //     key={i}
              //     data={location}
              //     doDeleteParking={this._onDeleteParking}
              //     doLocationStatusChange={this._doLocationStatusChange}
              //     onEditParking={this._onEditParking}
              //     doUpdateLocation={this.props.updateLocationRequest}
              //   />
              <h2>user</h2>
            );
          })} */}
        </Col>

        {/* {this.state.openEditParkingForm ? (
          <SideDrawer
            placement="left"
            title="Edit Parking"
            open={this.state.openEditParkingForm}
            onSideDrawerClose={() => {
              this.setState({
                openEditParkingForm: false,
              });
            }}
          >
            <FormEditParking
              currentEditParkingData={this.state.currentEditParkingData}
            />
          </SideDrawer>
        ) : null} */}
        {/* <Col xl={10} md={10} xs={24} className="dashboard-sample-view"> */}
        {/* <b>My Short Shout URL: </b>
            <a
              href={myShortShoutUrl}
              target="_BLANK"
              rel="noopener noreferrer"
              style={{
                color: "#1890FF",
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              {myShortShoutUrl}
            </a>
            <br />
            <br />
            <div className="sample-short-shout-box">
              <ShortShout
                username={loggedUserData.username}
                links={linksToShow}
              />
            </div> */}
        {/* </Col> */}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: state.admin.usersList.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetParkingsRequest: (data) =>
    dispatch(actions.adminGetParkingsRequest(data)),
  // myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  deleteParkingRequest: (data) => dispatch(actions.deleteParkingRequest(data)),
  updateLocationStatusRequest: (data) =>
    dispatch(actions.updateLocationStatusRequest(data)),
  updateLocationRequest: (data) =>
    dispatch(actions.updateLocationRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Parkings)
);

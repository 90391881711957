import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showProgressBar } from "../../services";

//final to use for all products page
export function* getCatalogProducts(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  let url = `/products`;
  try {
    const response = yield call(fireApi, "POST", url, action.data, header);
    const { data } = response;
    if (!data.error) {
      yield put(actions.getCatalogProducts.success(data.data));
      // is success call user data which will fetch product information and will be used to add/remove fav button
      yield put(actions.getMyFullDataRequest());
    }
  } catch (e) {
    yield put(actions.getCatalogProducts.failure(e.response.data));
  }
  yield put(actions.showProgressBar(false));
}

export function* getTopPriceDropProducts(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  let url = `/products`;
  try {
    const response = yield call(
      fireApi,
      "POST",
      url,
      { catalogType: "priceDropProducts", limit: 10 },
      header
    );
    const { data } = response;
    if (!data.error) {
      yield put(actions.getTopPriceDropProducts.success(data.data));
    }
  } catch (e) {
    yield put(actions.getTopPriceDropProducts.failure(e.response.data));
  }
  yield put(actions.showProgressBar(false));
}

export function* getTopPopularProducts(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  let url = `/products`;
  try {
    const response = yield call(
      fireApi,
      "POST",
      url,
      { catalogType: "popularProducts", limit: 10 },
      header
    );
    const { data } = response;
    if (!data.error) {
      yield put(actions.getTopPopularProducts.success(data.data));
    }
  } catch (e) {
    yield put(actions.getTopPopularProducts.failure(e.response.data));
  }
  yield put(actions.showProgressBar(false));
}

export function* getTopRecentProducts(action) {
  yield put(actions.showProgressBar(true));
  const header = {};
  let url = `/products`;
  try {
    const response = yield call(
      fireApi,
      "POST",
      url,
      { catalogType: "recentProducts", limit: 10 },
      header
    );
    const { data } = response;
    if (!data.error) {
      yield put(actions.getTopRecentProducts.success(data.data));
    }
  } catch (e) {
    yield put(actions.getTopRecentProducts.failure(e.response.data));
  }
  yield put(actions.showProgressBar(false));
}

import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";

const initialState = {
  register: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: {},
  },
  login: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  verifyEmail: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  sendEmailVerifyCode: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  addUsername: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  refreshToken: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  updatePassword: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  uploadAvatar: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  myAvatar: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
  myFullData: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    data: false,
  },
};

/* REGISTER */
const registerRequest = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
    },
  });

const registerSuccess = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Register Successfully!!" },
      data: { $set: action.payload },
    },
  });

const registerError = (state, action) =>
  update(state, {
    register: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
    },
  });
/* REGISTER */

/* LOGIN */
const loginRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const loginSuccess = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const loginError = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* LOGIN */

/* LOG OUT*/
const logoutRequest = (state, action) =>
  update(state, {
    login: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
    myFullData: {
      // reset my full data also as it is of no use after login and security purpose also
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* LOG OUT*/

/* reset password */
const resetPasswordRequest = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const resetPasswordSuccess = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const resetPasswordError = (state, action) =>
  update(state, {
    resetPassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* reset password */

/* verify email */
const verifyEmailRequest = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const verifyEmailSuccess = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const verifyEmailError = (state, action) =>
  update(state, {
    verifyEmail: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* verify email */

/* send Email Verify Code  */
const sendEmailVerifyCodeRequest = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const sendEmailVerifyCodeSuccess = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Login Successfully!" },
      data: { $set: action.payload },
    },
  });

const sendEmailVerifyCodeError = (state, action) =>
  update(state, {
    sendEmailVerifyCode: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* send Email Verify Code  */

/* add username  */
const addUsernameRequest = (state, action) =>
  update(state, {
    addUsername: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const addUsernameSuccess = (state, action) =>
  update(state, {
    addUsername: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Username added successfully!" },
      data: { $set: action.payload },
    },
  });

const addUsernameError = (state, action) =>
  update(state, {
    addUsername: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* addUsername */

/* get refresh token  */
const getRefreshTokenRequest = (state, action) =>
  update(state, {
    refreshToken: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const getRefreshTokenSuccess = (state, action) =>
  update(state, {
    refreshToken: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Refresh token fetched successfully!" },
      data: { $set: action.payload },
    },
  });

const getRefreshTokenError = (state, action) =>
  update(state, {
    refreshToken: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });
/* addUsername */

/* update password */
const updatePasswordRequest = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const updatePasswordSuccess = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Refresh token fetched successfully!" },
      data: { $set: action.payload },
    },
  });

const updatePasswordError = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

/* upload avatar  */
const uploadAvatarRequest = (state, action) =>
  update(state, {
    uploadAvatar: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const uploadAvatarSuccess = (state, action) =>
  update(state, {
    uploadAvatar: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Avatar uploaded successfully!" },
      data: { $set: action.payload },
    },
  });

const uploadAvatarError = (state, action) =>
  update(state, {
    updatePassword: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

/* my avatar  */
const myAvatarRequest = (state, action) =>
  update(state, {
    myAvatar: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const myAvatarSuccess = (state, action) =>
  update(state, {
    myAvatar: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Avatar uploaded successfully!" },
      data: { $set: action.payload },
    },
  });

const myAvatarError = (state, action) =>
  update(state, {
    myAvatar: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

/* usef full data   */
const getMyFullDataRequest = (state, action) =>
  update(state, {
    myFullData: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: false },
    },
  });

const getMyFullDataSuccess = (state, action) =>
  update(state, {
    myFullData: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "Avatar uploaded successfully!" },
      data: { $set: action.payload },
    },
  });

const getMyFullDataError = (state, action) =>
  update(state, {
    myFullData: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: action.payload },
      data: { $set: false },
    },
  });

export default handleActions(
  {
    [constants.REGISTER_REQUEST]: registerRequest,
    [constants.REGISTER_SUCCESS]: registerSuccess,
    [constants.REGISTER_ERROR]: registerError,

    [constants.LOGIN_REQUEST]: loginRequest,
    [constants.LOGIN_SUCCESS]: loginSuccess,
    [constants.LOGIN_ERROR]: loginError,

    [constants.LOGOUT_REQUEST]: logoutRequest,

    [constants.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
    [constants.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
    [constants.RESET_PASSWORD_ERROR]: resetPasswordError,

    [constants.VERIFY_EMAIL_REQUEST]: verifyEmailRequest,
    [constants.VERIFY_EMAIL_SUCCESS]: verifyEmailSuccess,
    [constants.VERIFY_EMAIL_ERROR]: verifyEmailError,

    [constants.SEND_EMAIL_VERIFY_CODE_REQUEST]: sendEmailVerifyCodeRequest,
    [constants.SEND_EMAIL_VERIFY_CODE_SUCCESS]: sendEmailVerifyCodeSuccess,
    [constants.SEND_EMAIL_VERIFY_CODE_ERROR]: sendEmailVerifyCodeError,

    [constants.ADD_USERNAME_REQUEST]: addUsernameRequest,
    [constants.ADD_USERNAME_SUCCESS]: addUsernameSuccess,
    [constants.ADD_USERNAME_ERROR]: addUsernameError,

    [constants.GET_REFRESH_TOKEN_REQUEST]: getRefreshTokenRequest,
    [constants.GET_REFRESH_TOKEN_SUCCESS]: getRefreshTokenSuccess,
    [constants.GET_REFRESH_TOKEN_ERROR]: getRefreshTokenError,

    [constants.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
    [constants.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
    [constants.UPDATE_PASSWORD_ERROR]: updatePasswordError,

    [constants.UPLOAD_AVATAR_REQUEST]: uploadAvatarRequest,
    [constants.UPLOAD_AVATAR_SUCCESS]: uploadAvatarSuccess,
    [constants.UPLOAD_AVATAR_ERROR]: uploadAvatarError,

    [constants.MY_AVATAR_REQUEST]: myAvatarRequest,
    [constants.MY_AVATAR_SUCCESS]: myAvatarSuccess,
    [constants.MY_AVATAR_ERROR]: myAvatarError,

    [constants.GET_MY_FULL_DATA_REQUEST]: getMyFullDataRequest,
    [constants.GET_MY_FULL_DATA_SUCCESS]: getMyFullDataSuccess,
    [constants.GET_MY_FULL_DATA_ERROR]: getMyFullDataError,
  },
  initialState
);

import React, { useState, useEffect } from "react";

function GoogleAdsenseResponsive(props) {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        marginTop: 10,
        textAlign: "center",
      }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block", height: "100px" }}
        data-ad-client="ca-pub-3682994999815575"
        data-ad-slot="9296353555"
        data-ad-format="fluid"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
}

export default GoogleAdsenseResponsive;

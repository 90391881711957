import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Input } from "antd";
import * as actions from "../../redux/actions";
import { showNotification } from "../../services/index.js";

const { Search } = Input;

function SearchBox(props) {
  const { websitesToProcess } = props;

  useEffect(() => {
    props.doSearchReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onTextSearch = (searchText) => {
    props.doSearchReset();
    if (searchText.trim() === "") {
      showNotification({
        type: "warning",
        title: "Enter text to search",
      });
    } else {
      // check if any website is seleted or not
      let selectedWebsites = websitesToProcess.filter(
        (m) => m.selectedForProcess === true
      );
      if (selectedWebsites.length > 0) {
        let url = "/price-search/" + searchText.trim();
        props.history.push(url);
      } else {
        showNotification({
          type: "warning",
          title: "Select at least one website",
        });
      }
    }
  };

  return (
    <Row id="landing-search-box">
      <Col className="" xl={24} md={24} xs={24}>
        <Search
          placeholder="What are you looking for today?"
          allowClear
          // enterButton="Search"
          size="large"
          onSearch={_onTextSearch}
          // style={{padding:20}}
          className="landing-search-inputbox"
          autoComplete="on"
        />
        <div className="search-text-hint">
          - Try to enter search text in details like e.g. iphone 13 mini 256 gb,
          jacket black blue, black socks
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  // locations: state.locations.locations.data || [],
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({
  doSearchReset: (data) => dispatch(actions.doSearchReset(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBox)
);

import React from "react";
import { Layout, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

const { Content } = Layout;

function AllStores(props) {
  const { websitesToProcess } = props;

  // const openWebsite = (w) => {
  //   if (w.landingPage) {
  //     window.open(w.landingPage, "_blank");
  //   }
  // };

  return (
    <>
      <div id="page-all-stores">
        <div className="websites-list">
          <div style={{ color: "#1f1c4f", textAlign: "center" }}>
            All stores:
          </div>
          <Row>
            {websitesToProcess
              .filter((w) => !w.isDisabled)
              .map((wp, index) => {
                const { selectedForProcess, logo, website } = wp;
                let blockClass = "website-block";
                if (!selectedForProcess) {
                  //   blockClass += " block-not-selected";
                }
                let storeUrl = "/store/" + website;
                return (
                  <Col
                    key={index}
                    className={blockClass}
                    xl={3}
                    md={3}
                    xs={4}
                    // onClick={() => openWebsite(wp)}
                  >
                    <Link to={storeUrl}>
                      {/* <a href={wp.landingPage} target="_BLANK"> */}
                      <img src={logo} className="website-logo" alt={website} />
                      {/* </a> */}
                    </Link>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllStores)
);

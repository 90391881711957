import { handleActions } from "redux-actions";
import update from "immutability-helper";
import constants from "../constants";
import * as actions from "../actions";

const initialState = {
  product: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  toggleFavouriteProduct: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
  },
  productLatestPriceRequestStatus: {
    isLoading: false,
  },
};

const getProductRequest = (state, action) => {
  return update(state, {
    product: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getProductSuccess = (state, action) => {
  return update(state, {
    product: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: { ...action.payload } },
    },
  });
};

const getProductError = (state, action) => {
  return update(state, {
    product: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const toggleFavouriteProductRequest = (state, action) => {
  return update(state, {
    toggleFavouriteProduct: {
      isLoading: { $set: true },
      isSuccess: { $set: false },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const toggleFavouriteProductSuccess = (state, action) => {
  return update(state, {
    toggleFavouriteProduct: {
      isLoading: { $set: false },
      isSuccess: { $set: true },
      isError: { $set: false },
      message: { $set: "" },
      data: { $set: { ...action.payload } },
    },
  });
};

const toggleFavouriteProductError = (state, action) => {
  return update(state, {
    toggleFavouriteProduct: {
      isLoading: { $set: false },
      isSuccess: { $set: false },
      isError: { $set: true },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};

const getProductLatestPrice = (state, action) => {
  console.log(action);
  // return update(state, {
  //   productLatestPriceRequestStatus: {
  //     isLoading: { $set: true },
  //   },
  // });
};

const getProductLatestPriceRequest = (state, action) => {
  return update(state, {
    productLatestPriceRequestStatus: {
      isLoading: { $set: true },
    },
  });
};

const getProductLatestPriceSuccess = (state, action) => {
  return update(state, {
    productLatestPriceRequestStatus: {
      isLoading: { $set: false },
    },
  });
};

const getProductLatestPriceFailure = (state, action) => {
  return update(state, {
    productLatestPriceRequestStatus: {
      isLoading: { $set: false },
    },
  });
};

export default handleActions(
  {
    [constants.GET_PRODUCT_REQUEST]: getProductRequest,
    [constants.GET_PRODUCT_SUCCESS]: getProductSuccess,
    [constants.GET_PRODUCT_ERROR]: getProductError,

    [constants.TOGGLE_FAVOURITE_PRODUCT_REQUEST]: toggleFavouriteProductRequest,
    [constants.TOGGLE_FAVOURITE_PRODUCT_SUCCESS]: toggleFavouriteProductSuccess,
    [constants.TOGGLE_FAVOURITE_PRODUCT_ERROR]: toggleFavouriteProductError,

    [actions.getProductLatestPrice.REQUEST]: getProductLatestPriceRequest,
    [actions.getProductLatestPrice.SUCCESS]: getProductLatestPriceSuccess,
    [actions.getProductLatestPrice.FAILURE]: getProductLatestPriceFailure,
  },
  initialState
);

import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showProgressBar } from "../../services";

export function* getMenu(action) {
  const header = {};
  let url = `/menu`;
  try {
    const response = yield call(fireApi, "POST", url, {}, header);
    const { data } = response;
    if (!data.error) {
      yield put(actions.getMenu.success(data.data));
    }
  } catch (e) {
    yield put(actions.getMenu.failure(e.response.data));
  }
}

export function* getWebsites(action) {
  const header = {};
  let url = `/websites/getWebsitesList`;
  try {
    const response = yield call(fireApi, "POST", url, {}, header);
    const { data } = response;
    if (!data.error) {
      yield put(actions.getWebsites.success(data.data));
      // need to set websitesToProcess for the very first time when websites are fetched under websearch
      yield put(actions.setWebsitesToProcessForFirstTime.success(data.data));
    }
  } catch (e) {
    yield put(actions.getWebsites.failure(e.response.data));
  }
}

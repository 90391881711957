import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

// import { Button, LinkButton } from "../UIComponents";

// import { isMobile } from "react-device-detect";

import * as actions from "../../../redux/actions";

import { GoogleAnalytics, Generic } from "../../../utils/index.js";

import ButtonFavouriteProduct from "../../../components/product/ButtonFavouriteProduct";

import "./index.scss";

function TemplateProductRowView(props) {
  //   const navigation = useNavigation();
  // console.log(props);
  const { data, showBuyNowButton, hasPriceHistory, showViewCount } = props;
  const {
    name,
    price,
    image,
    url,
    website,
    productId,
    viewCount,
    priceDiff,
    currency,
    productPageUrl,
  } = data;
  let webLogo =
    "https://cheapshops.in/api/static/images/websites_logo/" + website + ".png";

  let showAddRemoveFavButton = false;
  if (website && website !== "" && productId && productId !== "") {
    showAddRemoveFavButton = true;
  }

  const trackBuyNowClick = () => {
    GoogleAnalytics.trackEvent({
      category: "BuyNowClick",
      action: website,
      label: price,
      value: url,
    });

    props.buyNowClickRequest({
      website: website,
      productId: productId,
      price: price,
    });

    // Linking.openURL(data.url);
  };

  let showPriceDiff = false;
  let priceDiffTextColor = "#2c531f";
  if (priceDiff) {
    if (priceDiff > 0) {
      priceDiffTextColor = "red";
      showPriceDiff = `Price up ${currency}  ${priceDiff}`;
    } else {
      showPriceDiff = `Price drop ${currency}  ${priceDiff}`;
    }
  }

  //   const linkTo = useLinkTo();
  // console.log(priceDiff);
  // console.log(showPriceDiff);

  const openProductPage = () => {
    if (hasPriceHistory) {
      //   linkTo({
      //     screen: "ScreenProduct",
      //     params: { website: website, productId: productId },
      //   });
    } else {
      //   Linking.openURL(data.url);
    }
  };

  let parentViewHeight = 110;
  if (showBuyNowButton) {
    parentViewHeight = 130;
  }

  return (
    <Link to={productPageUrl}>
      <Col md={24} xl={24} sm={24} xs={24} className="TemplateProductRowView">
        <div style={{ width: "100%", height: 10 }}>
          {showPriceDiff ? (
            <div
              style={{
                color: priceDiffTextColor,
                fontSize: 8,
                textAlign: "center",
                backgroundColor: "yellow",
                fontWeight: "bold",
              }}
            >
              {showPriceDiff}
            </div>
          ) : null}
        </div>
        <Row style={{ height: "100%" }}>
          <Col xs={10} md={10} xl={10} className="left-block">
            <img src={image} className="product-image" />
          </Col>
          <Col xs={14} md={14} xl={14} style={{ padding: 10 }}>
            <div>
              <img
                src={webLogo}
                style={{
                  height: "15px",
                  // width: "100%",
                  // objectFit: "contain",
                  // resizeMode: "contain",
                  // alignItems: "center",
                  // display: "flex",
                  // justifyContent: "start",
                }}
              />
            </div>
            <div
              style={{
                // marginTop: ,
                fontSize: 16,
                color: "black",
                fontWeight: "bold",
              }}
              // ellipsizeMode="tail"
              // numberOfLines={3}
            >
              {currency}
              {price}
            </div>
            <div
              className="product-name"
              //   style={{
              //     // marginTop: ,
              //     fontSize: 10,
              //     color: "black",
              //     maxWidth: "100%",
              //     display: "-webkit-box",
              //     WebkitBoxOrient: "vertical",
              //     WebkitLineClamp: 3,
              //     overflow: "hidden",
              //     textOverflow: "ellipsis",
              //   }}
              // ellipsizeMode="tail"
              // numberOfLines={3}
            >
              {name}
            </div>
          </Col>
        </Row>
      </Col>
    </Link>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  buyNowClickRequest: (data) => dispatch(actions.buyNowClickRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateProductRowView);

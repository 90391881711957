import axios from "axios";
import jwt from "jsonwebtoken";
import { base_url, jwtsecret } from "./config";
import { notification } from "antd";
import { getCountryCode } from "../utils/Generic";

export const fireApi = function (method, api_url, data, headers) {
  headers = {
    ...headers,
    Authorization: getUserToken(),
    Accept: "application/json",
    "Content-Type": "application/json",
    // withCredentials: true,
    "Cache-Control": "no-cache",
    countryCode: getCountryCode(),
  };

  data = {
    countryCode: getCountryCode(),
    ...data,
  };

  const url = base_url + api_url;

  if (method === "GET") {
    return axios.get(url, { headers });
  } else if (method === "POST") {
    return axios.post(url, data, { headers });
  } else if (method === "PUT") {
    return axios.put(url, data, { headers });
  } else if (method === "DELETE") {
    return axios.delete(url, { data, headers });
  }
};

export const showNotification = ({ type, title, message }) => {
  notification[type]({
    duration: 2,
    message: title,
    description: message,
  });
};

export const setLoggedInSession = function (tokenVal) {
  localStorage.setItem("cheapshops-auth", tokenVal);
};

export const clearLoggedInSession = function () {
  localStorage.removeItem("cheapshops-auth");
  localStorage.removeItem("temp-isEmailVerified");
};

export const getLoggedUser = function () {
  let token = localStorage.getItem("cheapshops-auth");
  if (token) {
    let decodedToken = jwt.verify(token, jwtsecret);
    if (decodedToken && decodedToken.email) {
      decodedToken.token = token;
      return decodedToken;
    }
  }
  return false;
};

export const getUserToken = function () {
  return localStorage.getItem("cheapshops-auth") || "";
};

export const tempVariableToHold_isEmailVerified = function (val) {
  localStorage.setItem("temp-isEmailVerified", val);
};

export const get_tempVariableToHold_isEmailVerified = function () {
  return localStorage.getItem("temp-isEmailVerified") || false;
};

export const getUserShortShoutUrl = function (user) {
  return process.env.REACT_APP_WEB_BASE_URL + "/" + user.username;
};

const parseLocationObject = (locationObj) => {
  let fullName = "";
  if (locationObj && locationObj.locationName) {
    fullName =
      locationObj.locationName +
      ", " +
      locationObj.locationAddress +
      ", " +
      locationObj.locationCity +
      ", " +
      locationObj.locationState;
  }

  return {
    original: locationObj,
    fullName: fullName,
  };
};

const setPageTitle = (titleText) => {
  let title = "";
  if (titleText && titleText !== "") {
    title = titleText;
    title = title.replace(/,/g, " ");
  }

  if (title === "") {
    title = "Find Parking Space for Rent Exchange Sale - Parkinghunter.in";
  } else {
    title += " - ParkingHunter.in";
  }
  document.title = title;
};

export default {
  setPageTitle,
  parseLocationObject,
  getLoggedUser,
};

import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showProgressBar } from "../../services";

export function* productWebSearchRequest(action) {
  const { websiteObj, searchText } = action.payload;
  const { website } = websiteObj;
  const header = {};
  let url = `/scrapWebsite?website=` + website + `&name=` + searchText;
  try {
    const response = yield call(fireApi, "GET", url, {}, header);
    if (response) {
      yield put(actions.productWebSearchSuccess(response));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.productWebSearchError(e.response.data));
  }
}

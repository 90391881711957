import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Row, Col, Form, Input } from "antd";

import * as actions from "../../../redux/actions";

import { get_tempVariableToHold_isEmailVerified } from "../../../services";

class FormAddNewLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewParkingForm: false,
    };
  }

  componentDidMount() {
    if (this.props.loggedUserData) {
      // this.props.myParkingsRequest();
    }
  }

  _openNewParkingForm = () => {
    this.setState({
      openNewParkingForm: true,
    });
  };

  onFinish = (values) => {
    console.log(values);
    this.props.adminNewLocationRequest(values);
  };

  render() {
    let { loggedUserData, myParkings } = this.props;

    let isEmailVerified =
      (loggedUserData && loggedUserData.isEmailVerified) || false;
    if (isEmailVerified === false) {
      isEmailVerified = get_tempVariableToHold_isEmailVerified();
    }

    // locationName: { type: String }, // society name
    // locationCity: { type: String }, // sector, city etc.
    // locationState: { type: String }, // state
    // locationCountry: { type: String },

    return (
      <>
        <Row className="mydashboard-menu">
          <Col xl={24} md={24} xs={24}>
            <h3>Add New Location</h3>
            <Form
              // form={form}
              name="horizontal_login"
              layout="inline"
              onFinish={this.onFinish}
            >
              <Form.Item
                name="locationName"
                rules={[
                  {
                    required: true,
                    message: "Please enter location name!",
                  },
                ]}
              >
                <Input placeholder="Location Name" />
              </Form.Item>
              <Form.Item
                name="locationAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter location address!",
                  },
                ]}
              >
                <Input placeholder="Location Address" />
              </Form.Item>
              <Form.Item
                name="locationCity"
                rules={[
                  {
                    required: true,
                    message: "Please enter city!",
                  },
                ]}
              >
                <Input placeholder="Location City" />
              </Form.Item>
              <Form.Item
                name="locationState"
                rules={[
                  {
                    required: true,
                    message: "Please enter state!",
                  },
                ]}
              >
                <Input placeholder="Location State" />
              </Form.Item>
              <Form.Item
                name="locationCountry"
                rules={[
                  {
                    required: true,
                    message: "Please enter country!",
                  },
                ]}
              >
                <Input placeholder="Location country" />
              </Form.Item>
              <br />
              <br />
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    // disabled={
                    //   !form.isFieldsTouched(true) ||
                    //   form
                    //     .getFieldsError()
                    //     .filter(({ errors }) => errors.length).length
                    // }
                  >
                    Add Location
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {/* <MyParkings myParkings={myParkings} /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myParkings: state.parkings.myParkings.data || [],
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  adminNewLocationRequest: (data) =>
    dispatch(actions.adminNewLocationRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormAddNewLocation)
);

import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import * as actions from "../redux/actions";

const { Title } = Typography;

class RecentlyAddedUsers extends React.Component {
  componentDidMount() {
    this.props.getRecentlyAddedUsersRequest();
  }
  render() {
    let { recentlyAddedUsers } = this.props;
    return (
      <div className="recenlty-added-users-list">
        <Row>
          <Title className="title" level={4}>
            Recently Joined
          </Title>
        </Row>
        <Row>
          {recentlyAddedUsers.length > 0
            ? recentlyAddedUsers.map((user, index) => {
                let userShortShoutUrl =
                  process.env.REACT_APP_WEB_BASE_URL + "/" + user.username;
                return (
                  <Col
                    key={index}
                    xl={3}
                    lg={6}
                    md={6}
                    sm={8}
                    xs={12}
                    className="recently-added-user-avtar"
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        fontSize: "10px",
                      }}
                    >
                      <a href={userShortShoutUrl} target="_BLANK">
                        <Avatar
                          shape="square"
                          size={40}
                          style={{ backgroundColor: "#1890ff" }}
                          icon={<UserOutlined />}
                        />
                        <br />
                        <h3>@{user.username}</h3>
                      </a>
                    </div>
                  </Col>
                );
              })
            : null}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recentlyAddedUsers:
    (state.shortshout.recentlyAddedUsers &&
      state.shortshout.recentlyAddedUsers.data &&
      state.shortshout.recentlyAddedUsers.data.users) ||
    [],
});

const mapDispatchToProps = (dispatch) => ({
  getRecentlyAddedUsersRequest: (data) =>
    dispatch(actions.getRecentlyAddedUsersRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyAddedUsers);

import { combineReducers } from "redux";
import generic from "./generic/reducer";

import auth from "./auth/reducer";
// import links from "./links/reducer";
// import shortshout from "./shortshout/reducer";
// import dashboard from "./dashboard/reducer";

import admin from "./admin/reducer";
// import locations from "./locations/reducer";
// import parkings from "./parkings/reducer";

import websearch from "./websearch/reducer";
import product from "./product/reducer";
import products from "./products/reducer";

const appReducer = combineReducers({
  generic,
  auth,
  // links,
  // shortshout,
  // dashboard,
  admin,
  // locations,
  // parkings,
  websearch,
  product,
  products,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

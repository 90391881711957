import React from "react";
import { Button } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";

import * as actions from "../../redux/actions";

function AuthGoogle(props) {
  const onSuccessGoogle = (response) => {
    let payload = {
      isGoogleLogin: true,
      data: response,
    };
    props.loginRequest(payload);
  };
  const onErrorGoogle = (response) => {
    console.log(response);
  };

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log(tokenResponse);
  //   },
  // });
  return (
    <div>
      {/* <GoogleLogin
        clientId="322656443988-to9bdaefb46o9jsvongb59sl1v0vl80i.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={onSuccessGoogle}
        onFailure={onErrorGoogle}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            icon={<GoogleOutlined />}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Sign In With Google
          </Button>
        )}
      /> */}
      <GoogleOAuthProvider clientId="322656443988-fpr71l3kt76hjo8tg3qcpi0bq2jhobb3.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={onSuccessGoogle}
          onError={() => {
            console.log("Login Failed");
          }}
        />

        {/* <Button
          icon={<GoogleOutlined />}
          onClick={() => {
            useGoogleLogin({
              onSuccess: (codeResponse) => console.log(codeResponse),
              flow: "auth-code",
            });
          }}
          // disabled={renderProps.disabled}
        >
          Sign In With Google
        </Button> */}
      </GoogleOAuthProvider>
    </div>
  );
}

// export default AuthGoogle;

const mapStateToProps = (state) => ({
  login: state.auth.login,
});

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (data) => dispatch(actions.loginRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthGoogle)
);

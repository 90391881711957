import React, { useState, useEffect } from "react";
import { Row, Timeline, Radio } from "antd";
import moment from "moment";

import ProductWebSearch from "../ProductWebSearch";
import TemplateProductSmallView from "./TemplateProductSmallView";

const MatchedProducts = (props) => {
  const { products } = props;

  //   console.log(products);
  //   let data = [];
  //   if (productPrices && productPrices.length > 0) {
  //     productPrices.forEach((p) => {
  //       const { price, date } = p;
  //       if (price && date) {
  //         data.push({
  //           date: date,
  //           price: price,
  //         });
  //       }
  //     });
  //   }
  //   data.reverse();

  return (
    <>
      <h2 style={{ color: "#1f1c4f", fontWeight: "bold" }}>Similar Products</h2>
      <Row gutter={10}>
        {products.map((product, index) => {
          return (
            <ProductWebSearch
              data={product}
              key={index}
              showPriceHistoryLink={true}
            />
          );
        })}
      </Row>
      {/* <Row gutter={10}>
        {products.map((product, index) => {
          return <TemplateProductSmallView data={product} key={index} />;
        })}
      </Row> */}
    </>
  );
};

export default MatchedProducts;

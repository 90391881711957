import React, { useEffect } from "react";
import { connect } from "react-redux";
// import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/index.scss";
import LandingPage from "./containers/LandingPage";
import RouteChangeTracker from "./RouteChangeTracker";
// import AuthPage from "./containers/AuthPage";

import AuthRegisterPage from "./containers/AuthRegisterPage";
import AuthLoginPage from "./containers/AuthLoginPage";

import hideForAuthUser from "./components/auth/hideForAuthUser";

// import SearchContainer from "./containers/SearchContainer";
import MyDashboard from "./containers/MyDashboard";
// import ShortShoutMain from "./containers/ShortShoutMain";
import MasterAdmin from "./containers/MasterAdmin";
import requireUserAuth from "./components/auth/requireUserAuth";
import requireMasterAdminAuth from "./components/auth/requireMasterAdminAuth";
// import hideForAuthUser from "./components/auth/hideForAuthUser";

import PageWebSearch from "./containers/PageWebSearch";
import PageProduct from "./containers/PageProduct";
import Disclaimer from "./containers/Disclaimer";
import PageProducts from "./containers/PageProducts";
import PageAllStores from "./containers/PageAllStores";
import PageAllCategoires from "./containers/PageAllCategoires";

import PrivacyPolicy from "./containers/PrivacyPolicy";

import ProgressBarWrapper from "./components/ProgressBarWrapper";

import * as actions from "./redux/actions";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// if (process.env.NODE_ENV === "development") {
// } else {
// ReactGA.initialize("UA-39862096-12");
// ReactGA.pageview(window.location.pathname + window.location.search);
// }

function App(props) {
  const { menu } = props;
  // constructor(props) {
  //   super(props);
  //   // this.props.isLoggedInUser();

  //   // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
  // }

  useEffect(() => {
    props.getMenuRequest();
    props.getWebsitesRequest();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/register"
          component={hideForAuthUser(AuthRegisterPage)}
        />
        <Route exact path="/login" component={hideForAuthUser(AuthLoginPage)} />
        <Route
          // exact
          path="/price-search/:searchText"
          component={PageWebSearch}
        />

        <Route
          // exact
          path="/stores"
          component={PageAllStores}
        />

        <Route
          // exact
          path="/all-categories"
          component={PageAllCategoires}
        />

        <Route
          // exact
          path="/product/:website/:productId/:name"
          component={PageProduct}
        />

        <Route exact path="/products" component={PageProducts} />
        <Route exact path="/price-drop-products" component={PageProducts} />
        <Route exact path="/popular-products" component={PageProducts} />
        <Route exact path="/recent-products" component={PageProducts} />
        <Route path="/store/:website" component={PageProducts} />

        <Route
          // exact
          path="/products/:categoryId/:categoryName"
          component={PageProducts}
        />

        <Route
          path="/(mydashboard|mydashboard-addNewParking|mydashboard-editProfile|mydashboard-updatePassword)/"
          component={requireUserAuth(MyDashboard)}
        />

        <Route
          path="/(admin|admin-manageLocations|admin-manageUsers|admin-manageParkings|admin-manageLocationsRequest)/"
          component={requireMasterAdminAuth(MasterAdmin)}
        />

        <Route path="*">
          <NoMatch />
        </Route>
        {/* <Route exact path="/login" component={hideForAuthUser(AuthPage)} />
          <Route exact path="/register" component={hideForAuthUser(AuthPage)} />
          <Route
            exact
            path="/forgotPassword"
            component={hideForAuthUser(AuthPage)}
          />
          <Route
            // exact
            path="/society/:locationName"
            component={SearchContainer}
          />

          <Route
            path="/(mydashboard|mydashboard-addNewParking|mydashboard-editProfile|mydashboard-updatePassword)/"
            component={requireUserAuth(MyDashboard)}
          />
         */}

        {/* <Route path="/:shortShoutUsername" component={ShortShoutMain} /> */}
      </Switch>
      <RouteChangeTracker />
      <ProgressBarWrapper />
    </Router>
  );
}

function NoMatch() {
  window.location = "/";
  return null;
}

//export default App;

const mapStateToProps = (state) => ({
  menu: state.generic.menu.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  getWebsitesRequest: (data) => dispatch(actions.getWebsites.request()),
  getMenuRequest: (data) => dispatch(actions.getMenu.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

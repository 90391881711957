import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export default (ComposedComponent) => {
  class Authentication extends Component {
    componentWillMount() {
      if (
        !this.props.loggedUserData ||
        this.props.loggedUserData.role !== "masteradmin"
      ) {
        this.props.history.push(`/`);
      }
    }

    componentWillUpdate(nextProps) {
      if (
        !nextProps.loggedUserData ||
        nextProps.loggedUserData.role !== "masteradmin"
      ) {
        this.props.history.push(`/`);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      loggedUserData: state.auth.login.data || false,
    };
  }

  return withRouter(connect(mapStateToProps)(Authentication));
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Layout, Switch, Row, Col, Tabs } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";
// import Search from "../components/search";

import ProductWebSearch from "../components/ProductWebSearch";
import WebsitesLoader from "../components/WebsitesLoader";

import * as actions from "../redux/actions";

// import { WEBSITES_AVAILABLE } from "../services/config";

function PageWebSearch(props) {
  const [products, setProducts] = useState([]);
  const [showPriceHistoryLink, setShowPriceHistoryLink] = useState(true);
  const {
    match,
    productsToShow,
    websitesToProcess,
    sortOrder,
    productsExactMatchProducts,
    productsAllScrapedProducts,
    showAllSearchedProducts,
  } = props;
  const { params } = match;
  const { searchText } = params;
  // let websites = WEBSITES_AVAILABLE;
  let websites = [];

  const startScrapping = () => {
    // console.log(websitesToProcess);
    // console.log(websitesToProcess);

    let pendingToProcess = websitesToProcess.find(
      (m) => m.isProcessed === false && m.selectedForProcess === true
    );

    if (pendingToProcess) {
      // console.log(pendingToProcess);
      props.productWebSearchRequest({
        searchText: searchText,
        websiteObj: pendingToProcess,
      });
    } else {
      // setShowPriceHistoryLink(true);
    }

    // console.log(pendingToProcess);
    // props.productWebSearchRequest({
    //   searchText: searchText,
    //   websites: websites,
    // });
  };

  // useEffect(() => {
  //   // start scraping
  //   startScrapping();
  // }, []);

  useEffect(() => {
    // console.log(searchedProducts);
    setProducts(productsToShow);
  }, [productsToShow]);

  useEffect(() => {
    // console.log(searchedProducts);
    // console.log(
    //   "-------------------------------------------------------websitesToProcess"
    // );
    startScrapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websitesToProcess]);

  // console.log("----------------------productsToShow");
  // console.log(products);
  // console.log(products);
  // console.log(products);

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  let tabLabelExactCount =
    "Exact match (" + productsExactMatchProducts.length + ")";
  let tabLabelAllCount = "All (" + productsAllScrapedProducts.length + ")";

  return (
    <div id="search-page">
      <HeaderMain />
      {/* <Content className="page-content"><Search /></Content> */}
      {/* <FooterMain /> */}
      <div className="page-content">
        <Row>
          <Col md={12} xl={12} sm={12} xs={24}>
            <h2 style={{ marginLeft: 0, fontSize: 14, color: "#1f1c4f" }}>
              Search results for - {searchText}
            </h2>
          </Col>

          {/* <Col
            md={6}
            xl={6}
            sm={6}
            xs={12}
            style={{
              textAlign: "right",
              paddingRight: "10px",
              color: "#1f1c4f",
            }}
          >
            <Switch onChange={props.toggleShowAllSearchProducts} /> Show all
          </Col>
          <Col
            md={6}
            xl={6}
            sm={6}
            xs={12}
            style={{ textAlign: "right", paddingRight: "10px" }}
          >
            <select
              onChange={(e) => props.changeSortSearchProducts(e.target.value)}
            >
              {sortOrder.map((s, index) => {
                return (
                  <option
                    key={index}
                    value={s.key}
                    selected={s.selected ? true : false}
                  >
                    {s.label}
                  </option>
                );
              })}
            </select>
          </Col> */}
        </Row>

        <Row style={{ fontSize: 10 }}>
          {/* <Col
            md={3}
            xl={3}
            sm={12}
            xs={12}
            style={{
              border: "2px solid #e0e6f8",
              background: "#eff8fb",
              padding: 5,
              fontWeight: !showAllSearchedProducts ? "bold" : 100,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => props.toggleShowAllSearchProducts(false)}
          >
            {productsExactMatchProducts.length} Exact match found
          </Col>
          <Col
            md={3}
            xl={3}
            sm={12}
            xs={12}
            style={{
              textAlign: "right",
              border: "2px solid #e0e6f8",
              background: "#eff8fb",
              padding: 5,
              fontWeight: showAllSearchedProducts ? "bold" : 100,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => props.toggleShowAllSearchProducts(true)}
          >
            Total products {productsAllScrapedProducts.length}
          </Col> */}
          <Col
            md={3}
            xl={3}
            sm={12}
            xs={12}
            style={
              {
                // border: "2px solid #e0e6f8",
                // padding: 5,
                // textAlign: "center",
              }
            }
          >
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/recent-products"
            >
              View recent products
            </Link>
          </Col>
          <Col
            md={3}
            xl={3}
            sm={12}
            xs={12}
            style={{
              // border: "2px solid #e0e6f8",
              // padding: 5,
              textAlign: "right",
            }}
          >
            <select
              onChange={(e) => props.changeSortSearchProducts(e.target.value)}
            >
              {sortOrder.map((s, index) => {
                return (
                  <option
                    key={index}
                    value={s.key}
                    selected={s.selected ? true : false}
                  >
                    {s.label}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>

        <WebsitesLoader websitesList={websites} />

        <Row>
          <Col md={24} xl={24} sm={24} xs={24}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab={tabLabelExactCount} key="1">
                {products.map((product, index) => {
                  return (
                    <ProductWebSearch
                      data={product}
                      key={index}
                      showPriceHistoryLink={showPriceHistoryLink}
                    />
                  );
                })}
              </Tabs.TabPane>
              <Tabs.TabPane tab={tabLabelAllCount} key="2">
                {productsAllScrapedProducts.map((product, index) => {
                  return (
                    <ProductWebSearch
                      data={product}
                      key={index}
                      showPriceHistoryLink={showPriceHistoryLink}
                    />
                  );
                })}
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
      <FooterMain />
    </div>
  );
}

const mapStateToProps = (state) => ({
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
  productsToShow: state.websearch.productWebSearch.data || [],
  productsExactMatchProducts:
    state.websearch.productWebSearch.dataExactMatchProducts || [],
  productsAllScrapedProducts:
    state.websearch.productWebSearch.dataAllScrapedProducts || [],
  sortOrder: state.websearch.productWebSearch.sortOrder || [],
  showAllSearchedProducts:
    state.websearch.productWebSearch.showAllSearchedProducts || false,
});

const mapDispatchToProps = (dispatch) => ({
  productWebSearchRequest: (data) =>
    dispatch(actions.productWebSearchRequest(data)),
  toggleShowAllSearchProducts: (data) =>
    dispatch(actions.toggleShowAllSearchProducts(data)),
  changeSortSearchProducts: (data) =>
    dispatch(actions.changeSortSearchProducts(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageWebSearch);

// export default withRouter(PageWebSearch);

//TODO: change to functional component
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { HomeOutlined } from "@ant-design/icons";

import { Menu, Dropdown, Space, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import * as actions from "../../redux/actions";

// import SideDrawer from "../SideDrawer";

class Auth extends React.Component {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.props.isLoggedInUser();
    this.state = {
      // openLoginForm: false,
      // openRegisterForm: false,
      // openSideDrawer: false,
      // sideDrawerType: "",
      // sideDrawerTitle: "",
    };
  }

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.login.data &&
    //   this.props.login.data !== prevProps.login.data
    // ) {
    //   this.setState({
    //     openSideDrawer: false,
    //   });
    // }
    // if (
    //   this.props.login.isSuccess &&
    //   prevProps.login.isSuccess != this.props.login.isSuccess
    // ) {
    //   if (
    //     this.props.login &&
    //     this.props.login.data &&
    //     this.props.login.data.role
    //   ) {
    //     if (this.props.login.data.role == "masteradmin") {
    //       this.props.history.push("/mydashboard");
    //     } else {
    //       this.props.history.push("/mydashboard");
    //     }
    //   }
    // }
  }

  _doLogout = (values) => {
    this.props.logoutRequest();
    this.props.closeDrawer();
  };

  render() {
    let { loggedUserData } = this.props;
    let yesLoggedUser =
      (loggedUserData &&
        loggedUserData.token &&
        loggedUserData.email &&
        loggedUserData.name) ||
      false;
    let loggedUserRole = loggedUserData.role;

    let menuItems = [
      {
        label: (
          <Link to="/">
            <Button type="link" className="auth-menu-link">
              Home
            </Button>
          </Link>
        ),
        key: "1",
      },
    ];
    if (yesLoggedUser) {
      menuItems.push({
        label: (
          <Link to="/mydashboard">
            <Button type="link" className="auth-menu-link">
              My Favourites
            </Button>
          </Link>
        ),
        key: "4",
      });
      menuItems.push({
        label: (
          <Link to="/mydashboard-updatePassword">
            <Button type="link" className="auth-menu-link">
              Profile
            </Button>
          </Link>
        ),
        key: "5",
      });
      if (loggedUserRole === "masteradmin") {
        menuItems.push({
          label: (
            <Link to="/admin">
              <Button type="link" className="auth-menu-link">
                Admin
              </Button>
            </Link>
          ),
          key: "3",
        });
      }
    } else {
      menuItems.push({
        label: (
          <Link to="/login" style={{ width: "100%" }}>
            <Button
              type="primary"
              className="auth-menu-link"
              style={{ width: "100%" }}
            >
              Sign In
            </Button>
          </Link>
        ),
        key: "2",
      });
      menuItems.push({
        label: (
          <Link to="/register" style={{ width: "100%" }}>
            <Button
              type="primary"
              className="auth-menu-link"
              style={{ width: "100%" }}
            >
              Sign Up
            </Button>
          </Link>
        ),
        key: "3",
      });
    }

    if (yesLoggedUser) {
      menuItems.push({
        type: "divider",
      });
      menuItems.push({
        label: (
          <Link to="/" onClick={this._doLogout}>
            <Button type="link" className="auth-menu-link">
              Log Out
            </Button>
          </Link>
        ),
        key: "6",
      });
    }

    return (
      <div className="wrapper-auth-menu">
        <Menu
          // defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="light"
          // inlineCollapsed={collapsed}
          items={menuItems}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  logoutRequest: (data) => dispatch(actions.logoutRequest(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));

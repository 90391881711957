import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import SERVICE from "../services";

function Menu(props) {
  const { menu } = props;
  const storeMenu = menu.storeMenu || [];
  return (
    <div id="menu">
      <div className="heading">All Categories</div>
      {storeMenu.map((categoryData, index1) => {
        const { categoryName, subCategory } = categoryData;
        return (
          <div className="category-block" key={index1}>
            <div className="category-name">{categoryName}</div>
            <Row>
              {subCategory.map((category, index) => {
                const { categoryName, categoryId, categoryUrl } = category;
                return (
                  <Col
                    key={index}
                    className="sub-category-name"
                    xl={4}
                    md={4}
                    xs={12}
                  >
                    <Link to={categoryUrl}>{categoryName}</Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  menu: state.generic.menu.data || {},
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

import React, { useState, useEffect } from "react";
// import { Spin } from "antd";
import { connect } from "react-redux";
import { ProgressBar } from "react-loader-spinner";

import * as actions from "../redux/actions";

function ProgressBarWrapper(props) {
  const [progressBarStatus, setProgressBarStatus] = useState(false);
  useEffect(() => {
    setProgressBarStatus(props.progressBarStatus);
  }, [props.progressBarStatus]);
  return (
    <>
      {progressBarStatus ? (
        <div id="progress-bar-wrapper">
          {/* <Spin className="spinner" size="large" tip="Loading..." /> */}
          <ProgressBar
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            borderColor="yellow"
            barColor="#1890ff"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="spinner"
          />
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  progressBarStatus: state.generic.progressBar.data || false,
});
const mapDispatchToProps = (dispatch) => ({
  showProgressBar: (data) => dispatch(actions.showProgressBar(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarWrapper);
